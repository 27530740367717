import { PutApiUpdateRequestBody } from "@superblocksteam/schemas";
import {
  Actions,
  Organization,
  PLUGIN_ID_TO_PROTO_CLASS,
  VersionedEntityType,
} from "@superblocksteam/shared";
import { call, delay, put, select } from "redux-saga/effects";
import envs from "env";
import { API_STATUS_CODES } from "legacy/constants/ApiConstants";
import { ReduxActionTypes } from "legacy/constants/ReduxActionConstants";
import { SideBarKeys } from "legacy/pages/Editor/constants";
import { getWorkflowSidebarKey } from "legacy/selectors/editorPreferencesSelector";
import {
  getCurrentBranch,
  getIsLeftPanePinned,
} from "legacy/selectors/editorSelectors";
import selectLastSuccessfulWrite from "legacy/selectors/successfulWriteSelector";
import { lock } from "store/slices/apisShared/sharedPersistApiLock";
import { fetchCommitsSaga } from "store/slices/commits";
import { Flag, selectFlagById } from "store/slices/featureFlags";
import { selectOnlyOrganization } from "store/slices/organizations";
import {
  HttpError,
  EntitiesErrorType,
  typeSafeEntries,
} from "store/utils/types";
import logger from "utils/logger";
import { callSagas, createSaga, SagaType } from "../../../utils/saga";
import { clearDirtyState } from "../actions";
import { persistApiV3 } from "../client";
import slice from "../slice";
import { ApiDto, ApiTriggerType } from "../types";

function* persistApiInternal({
  api: updated,
  shouldUpdateState,
}: {
  //TODO: support Partial<ApiDto> so we do not need send the full api everytime we make a partial change (backend already support receiving partial ApiDto)
  api: ApiDto;
  // shouldUpdateState indicates whether or not to update the API's value in redux
  // with what is returned from the server. We should only set this to true when we
  // care about the updated API from the server (e.g. REST API integration disabled fields)
  shouldUpdateState?: boolean;
}) {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  let unlock = () => {};
  try {
    unlock = yield call(lock, updated.id);
    const lastSuccessfulWrite: number = yield select(selectLastSuccessfulWrite);

    yield put(clearDirtyState.create({ id: updated.id }));

    const { pluginExecutionVersions }: Organization = yield select(
      selectOnlyOrganization,
    );

    const requestBody: PutApiUpdateRequestBody = {
      name: updated.name,
      actions: updated.actions as Actions,
      settings: updated.settings,
      sendEmailOnFailure: updated.sendEmailOnFailure,
      scheduleState: updated.scheduleState,
      lastSuccessfulWrite:
        Number(lastSuccessfulWrite) === -1
          ? Number(updated.updated)
          : lastSuccessfulWrite,
    };
    for (const [id, action] of typeSafeEntries(
      requestBody?.actions?.actions || {},
    )) {
      if (action && requestBody.actions) {
        if ((action as any).organizationId)
          delete (action as any).organizationId;
        let config = action.configuration;
        // If this plugin is using the protobuf type we need to persist to the wire format
        if (Object.keys(PLUGIN_ID_TO_PROTO_CLASS).includes(action.pluginId)) {
          const ProtoClass = PLUGIN_ID_TO_PROTO_CLASS[action.pluginId];
          const protoType = new ProtoClass(config);
          config = protoType.toJson();
        }
        requestBody.actions.actions[id] = {
          ...action,
          configuration: {
            ...action.configuration,
            superblocksMetadata: {
              pluginVersion: pluginExecutionVersions
                ? pluginExecutionVersions[action.pluginId]
                : undefined,
            },
          },
        };
      }
    }
    const superblocksSupportUpdateEnabled: boolean = yield select(
      selectFlagById,
      Flag.ENABLE_SUPERBLOCKS_SUPPORT_UPDATES,
    );
    const branch: ReturnType<typeof getCurrentBranch> = yield select(
      getCurrentBranch,
    );
    const api: ApiDto = yield call(
      persistApiV3,
      updated.id,
      requestBody,
      superblocksSupportUpdateEnabled,
      branch?.name,
    );

    yield put({
      type: ReduxActionTypes.UPDATE_LAST_SUCCESSFUL_WRITE,
      payload: api.updated,
    });

    // we also need to refetch the autosaves if the versions panel is pinned
    // but only for workflows and scheduled jobs here, applications are handled inside EvaluationsSaga
    const isLeftPanePinned: boolean = yield select(getIsLeftPanePinned);
    const sidebarKey: SideBarKeys = yield select(getWorkflowSidebarKey);
    if (
      sidebarKey === SideBarKeys.VERSIONS &&
      isLeftPanePinned &&
      updated.triggerType !== ApiTriggerType.UI
    ) {
      yield delay(1000);

      yield callSagas([
        fetchCommitsSaga.apply({
          entityId: updated.id,
          entityType:
            updated.triggerType === ApiTriggerType.WORKFLOW
              ? VersionedEntityType.WORKFLOW
              : VersionedEntityType.SCHEDULED_JOB,
        }),
      ]);
    }

    return { api, shouldUpdateState };
  } catch (err) {
    logger.debug(`Errored when persisting the API: ${err}`);
    throw err;
  } finally {
    unlock();
  }
}

export const persistApiSaga = createSaga(persistApiInternal, "persistApiSaga", {
  sliceName: "apis",
  type: SagaType.Throttled,
  delay: Number(envs.get("SUPERBLOCKS_UI_PERSIST_API_DEBOUNCE_MS")),
  keySelector: (payload) => payload.api.id,
});

slice.saga(persistApiSaga, {
  start(state, { payload }) {
    state.meta[payload.api.id] = state.meta[payload.api.id] ?? {};
    state.meta[payload.api.id].saving = true;
  },
  success(state, { payload }) {
    if (!payload) {
      // You reach this branch because of the try-catch in the internal saga
      // func.
      return;
    }
    if (payload.shouldUpdateState) {
      state.entities[payload.api.id] = payload.api;
    }
    delete state.meta[payload.api.id].saving;
    delete state.errors[payload.api.id];
    state.meta[payload.api.id].savingFailuresCount = 0;
  },
  error(state, { payload, meta }) {
    state.meta[meta.args.api.id].dirty = true;
    state.errors[meta.args.api.id] = {
      error: payload,
      type: EntitiesErrorType.SAVE_ERROR,
    };
    delete state.meta[meta.args.api.id].saving;
    state.meta[meta.args.api.id].savingFailuresCount =
      (state.meta[meta.args.api.id].savingFailuresCount ?? 0) + 1;

    if (
      payload instanceof HttpError &&
      payload.code === API_STATUS_CODES.RESOURCE_CONFLICT
    ) {
      state.errors.stale = { error: payload };
    }
  },
});
