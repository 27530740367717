/* eslint-disable import/no-unused-modules */
import { Diff } from "deep-diff";
import { get } from "lodash";
import type * as BackendTypes from "store/slices/apisV2/backend-types";

type DSLDiff = Diff<BackendTypes.Api, BackendTypes.Api>;

const UPDATED_BLOCK_NAME = "updatedBlockName";
const BLOCKS = "blocks";
const NAME = "name";

/**
 * this function updates the replay object to hold information about the
 * changes that occured in the block, so that we can focus on the affected block
 * after a replay has occured
 */
export function processDiff(
  dsl: BackendTypes.Api,
  diff: DSLDiff,
  replay: any,
  isUndo: boolean,
) {
  if (!diff || !diff.path || !diff.path.length || replay[UPDATED_BLOCK_NAME])
    return;
  let updateBlockName;
  switch (diff.kind) {
    case "N":
      updateBlockName = getBlockName(dsl, diff);
      break;
    case "D":
      updateBlockName = getBlockName(dsl, diff);
      break;
    case "A":
      if (diff.item.kind === "N") {
        if (isUndo) {
          updateBlockName = getBlockName(dsl, diff, true);
        } else {
          updateBlockName = (diff.item.rhs as any)?.name;
        }
      } else if (diff.item.kind === "D") {
        if (!isUndo) {
          updateBlockName = getBlockName(dsl, diff, true);
        } else {
          updateBlockName = (diff.item.lhs as any)?.name;
        }
      }
      break;
    case "E":
      if (diff.path[diff.path.length - 1] === NAME) {
        if (isUndo) {
          updateBlockName = (diff as any).lhs;
        } else {
          updateBlockName = (diff as any).rhs;
        }
      } else {
        updateBlockName = getBlockName(dsl, diff);
      }
      break;
  }

  replay[UPDATED_BLOCK_NAME] = updateBlockName;
}

const getBlockName = (
  dsl: BackendTypes.Api,
  diff: DSLDiff,
  ignoreLastBlock = false,
) => {
  if (!diff.path || diff.path.length < 1) return;
  const blocks = dsl[BLOCKS];
  if (!ignoreLastBlock && diff.path[diff.path.length - 1] === "blocks") {
    const block = get(blocks, [...diff.path.slice(1), 0]);
    if (block) {
      return block?.name;
    }
  }
  for (let i = diff.path.length - 1; i >= 0; i--) {
    if (typeof diff.path[i] === "number") {
      const block = get(blocks, diff.path.slice(1, i + 1));
      return block?.name;
    }
  }
};
