import { ApiResponseType } from "@superblocksteam/shared/src/types/page";
import { omit } from "lodash";
import TokenProvider from "auth/token";
import { WidgetProps } from "legacy/widgets/BaseWidget/types";
import { ComponentEditAction, SortedProperties } from "./types";

const AI_EDIT_COMPONENT_URL = "v1/ai/edit-component";

export const sendAiWidgetEditActionsFeedback = async ({
  actionsRequestId,
  feedback,
  comment,
  expectedActions,
}: {
  actionsRequestId: string;
  feedback: "accept" | "deny" | "exit" | "redo";
  comment?: string;
  expectedActions?: ComponentEditAction[];
}): Promise<void> => {
  const { callServer } = await import(
    /* webpackChunkName: "client-utils" */ "store/utils/client"
  );
  const url = `${AI_EDIT_COMPONENT_URL}/feedback`;

  await callServer({
    url,
    method: "POST",
    body: {
      actionsRequestId,
      feedback,
      comment,
      jwt: TokenProvider.getToken(),
      expected: expectedActions,
    },
  });
};

export const getAiWidgetEditActionsStream = async ({
  existingWidget,
  prompt,
  rawPrompt,
  context,
  signal,
  onMessage,
  isTest,
}: {
  existingWidget: Partial<WidgetProps> | SortedProperties;
  prompt: string;
  rawPrompt: string;
  context: Record<string, any>;
  signal?: AbortSignal;
  onMessage: (message: any) => void;
  isTest: boolean;
}) => {
  const { callHelper } = await import(
    /* webpackChunkName: "client-utils" */ "store/utils/client"
  );
  const componentType = existingWidget.type
    ?.toLowerCase()
    .replace("_widget", "");

  await callHelper(
    {
      method: "POST",
      url: AI_EDIT_COMPONENT_URL,
      dontAppendStream: true,
      responseType: ApiResponseType.STREAM,
      query: {
        component: componentType ?? "",
      },
      body: {
        context,
        component: omit(existingWidget, ["type", "widgetId"]),
        type: componentType,
        prompt,
        rawPrompt,
        jwt: TokenProvider.getToken(),
        test: isTest,
        params: {
          provider: localStorage.getItem("ai-provider"),
          component: componentType,
        },
      },
      headers: {
        "Content-Type": "application/json",
      },
      signal,
    },
    {
      notifyOnError: true,
    },
    onMessage,
  );
};
