import { IApiV3Dto, ViewMode } from "@superblocksteam/shared";
import { call, select } from "redux-saga/effects";
import ApplicationApi from "legacy/api/ApplicationApi";
import LayoutApi from "legacy/api/LayoutApi";
import { getCurrentBranch } from "legacy/selectors/editorSelectors";
import { selectControlFlowEnabledDynamic } from "store/slices/apisShared/selectors";
import { getV2ApiId } from "store/slices/apisV2/utils/getApiIdAndName";
import { ROOT } from "store/utils/types";
import { createSaga } from "../../../utils/saga";

import slice, { type ApiDtoWithPb, type ApiV2State } from "../slice";

interface FetchAllApisPayload {
  applicationId: string;
  pageId?: string;
}

function* fetchAllV2ApisFromApplicationInEditModeSaga({
  applicationId,
  pageId,
}: FetchAllApisPayload) {
  const controlFlowEnabled: boolean = yield select(
    selectControlFlowEnabledDynamic,
  );

  if (!controlFlowEnabled) {
    return [];
  }

  const branch: ReturnType<typeof getCurrentBranch> = yield select(
    getCurrentBranch,
  );

  const result: Awaited<
    ReturnType<typeof ApplicationApi.fetchSingleApplication>
  > = yield call(ApplicationApi.fetchSingleApplication, {
    applicationId,
    viewMode: ViewMode.EDITOR,
    branch: branch?.name,
  });
  const applicationAPIs = result.data.apis ?? [];

  let pageAPIs: IApiV3Dto[] = [];
  if (pageId) {
    const pageResponse: Awaited<ReturnType<typeof LayoutApi.getPage>> =
      yield call(
        LayoutApi.getPage,
        applicationId,
        pageId,
        ViewMode.EDITOR,
        branch?.name,
        undefined,
      );
    pageAPIs = pageResponse.data.apis ?? [];
  }

  const v2Apis: IApiV3Dto[] = [];

  [...applicationAPIs, ...pageAPIs].forEach((api) => {
    if (api.apiPb) {
      v2Apis.push(api);
    }
  });

  return v2Apis;
}

export const getAllV2ApisSaga = createSaga(
  fetchAllV2ApisFromApplicationInEditModeSaga,
  "fetchAllV2ApisSaga",
  {
    sliceName: slice.name,
  },
);

slice.saga(getAllV2ApisSaga, {
  start(state, { payload }) {
    state.loading[ROOT] = true;
    state.meta[ROOT] = { applicationId: payload.applicationId };
    delete state.errors[payload.applicationId];
  },
  success(state, { payload }) {
    state.entities = payload.reduce((result, api) => {
      const apiId = getV2ApiId(api);
      result[apiId] = api as unknown as ApiDtoWithPb;
      return result;
    }, {} as Record<string, ApiDtoWithPb>);
    delete state.loading[ROOT];
    Object.values(payload).forEach((api) => {
      const apiId = getV2ApiId(api);
      state.meta[apiId] = state.meta[apiId] ?? {};
      state.meta[apiId].needsBindingExtraction = true;
    });
  },
  error(state, { payload }) {
    state.errors[ROOT] = { error: payload };
    delete state.loading[ROOT];
  },
  store(state, { payload }) {
    modifyState(state, payload);
  },
});

const modifyState = (state: ApiV2State, payload: IApiV3Dto[]): void => {
  state.entities = payload.reduce((result, api) => {
    result[getV2ApiId(api)] = api as unknown as ApiDtoWithPb;
    return result;
  }, {} as Record<string, ApiDtoWithPb>);
  delete state.loading[ROOT];
  Object.values(payload).forEach((api) => {
    const apiId = getV2ApiId(api);
    state.meta[apiId] = state.meta[apiId] ?? {};
    state.meta[apiId].needsBindingExtraction = true;
  });
};
