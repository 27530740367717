// Necessary for our custom types
// eslint-disable-next-line no-restricted-imports
import { useSelector, useDispatch } from "react-redux";
import type { AppState } from "./types";

// This type will become much more strict once we upgrade to the latest Redux
export const useAppDispatch = useDispatch<any>;

export const useAppSelector = <T>(
  fn: (state: AppState) => T,
  equalityFn?: (left: T, right: T) => boolean,
): T => useSelector<AppState, T>(fn, equalityFn);
