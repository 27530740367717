/* eslint-disable import/no-unused-modules */
import { get, set } from "lodash";

const UPDATES = "propertyUpdates";

/**
 * checks the existing value and sets the propertyUpdate if required
 */
export function setPropertyUpdate(
  replay: any,
  path: string[],
  value: string[],
) {
  const existingPathValue = get(replay, path);
  // existingPathValue > 2 means we are updating a list property control (eg table primary columns)
  if (!existingPathValue || existingPathValue.length > 2) {
    set(replay, path, value);
    set(replay, UPDATES, true);
  }
}

/**
 * pushes value to array element in array of objects
 */
export function addToArray(obj: any, key: string, value: any) {
  if (!obj) return;

  if (obj[key] && Array.isArray(obj[key])) {
    obj[key].push(value);
  } else {
    obj[key] = [value];
  }
}

/**
 * creates paths changed from diffs  array
 */
export function getPathsFromDiff(diffs: any) {
  const paths = [];

  for (const diff of diffs) {
    paths.push(diff.path.join("."));
  }

  return paths;
}
