export const colors = {
  WHITE: "#FFFFFF",
  BLACK: "#000000",
  PLATFORM_BLACK: "#0E141B",

  // TODO: Consider replace WHITE with this.
  OFF_WHITE: "#F2F2F2",
  DISABLED: "rgba(0, 0, 0, 0.25)",

  GREY_25: "#F9FAFB",
  GREY_50: "#F3F4F6",
  GREY_100: "#E8EAED",
  GREY_200: "#C6CAD2",
  GREY_300: "#A4AAB7",
  GREY_400: "#818A9C",
  GREY_500: "#6C7689",
  GREY_600: "#5A6272",
  GREY_700: "#454D5F",
  GREY_800: "#2F3437",
  GREY_900: "#121517",
  GRAY_GRID: "#CCD3DB",

  RED_500_8: "rgba(255, 72, 72, 0.08)",
  RED_25: "#FF48481F",
  RED_500: "#FF4848",
  RED_600: "#DB4949",
  RED_700: "#C54141",

  ORANGE_25: "#FFEEC5",
  ORANGE_600: "#FA8A0F",

  SUBTLE_BLUE: "#29bbff14", // Deprecated. Not defined in figma
  SUBTLE_BLUE_SOLID: "#eefaff", // Deprecated. Not defined in figma

  ACCENT_BLUE_NEW_DARKER: "#0087E0",
  BLUE_MINMAX_LABELS: "#0062A3",
  DRAG_PREVIEW_BLUE: "rgba(0, 135, 224, 0.16)",
  ACCENT_BLUE_500: "#27BBFF",
  ACCENT_BLUE_500_25: "#27BBFF14",
  ACCENT_BLUE_500_50: "#27BBFF1F",
  ACCENT_BLUE_500_18: "#27BBFF2E", // Deprecated. Not defined in figma
  ACCENT_BLUE_500_24: "#27BBFF3D", // Deprecated. Not defined in figma
  ACCENT_BLUE_500_48: "#27BBFF7A", // Deprecated. Not defined in figma
  ACCENT_BLUE_600: "#00A8F5",
  ACCENT_BLUE_700: "#009AE0",
  ACCENT_ORANGE: "#FF9F35",
  LIGHT_ORANGE: "#FF9F351E",
  ACCENT_ORANGE_600: "#FA8A0F",
  SUBTLE_PURPLE: "#643ADF14",
  ACCENT_PURPLE: "#643ADF",
  LIGHT_PURPLE: "#643ADF1E",
  ACCENT_PURPLE_500: "#7C4FF8",
  ACCENT_PURPLE_600: "#5227CE",
  SUBTLE_GREEN: "#14CDB714",
  LIGHT_GREEN: "#9BDCAD",
  ACCENT_GREEN: "#14CDB7",
  ACCENT_GREEN_600: "#08BAA5",
  LIGHT_PINK: `rgba(255, 98, 164, 0.12)`,
  ACCENT_PINK: "#FF62A4",

  HOVER_BLUE: "#E3F8FF",
  HOVER_GREEN: "#14CDB724",

  CLICK_GREEN: "#14CDB734",

  INFO: "#27BBFF",
  WARNING: "#FF9F35",
  DANGER: "#F45252",
  DANGER_BRIGHT: "rgba(255, 72, 72, 1.0)",
  DANGER_SUBTLE: "#F452521F",
  SUCCESS: "#0CC26D",

  NONE: "transparent",
} as const;

export type SuperblocksColor = keyof typeof colors;
