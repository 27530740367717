import { BranchDto } from "@superblocksteam/shared";
import moment from "moment";
import { stopEvaluation } from "legacy/actions/evaluationActions";
import {
  createPageInit,
  createPageSuccess,
  duplicatePageInit,
  initCanvasLayout,
  requestPageSave,
  savePageSuccess,
} from "legacy/actions/pageActions";
import { API_STATUS_CODES } from "legacy/constants/ApiConstants";
import {
  ReduxAction,
  ReduxActionTypes,
  ReduxActionErrorTypes,
  WidgetOperationsWithCompletionListeners,
} from "legacy/constants/ReduxActionConstants";
import {
  LocalDevServerState,
  LocalDevServerStatus,
} from "legacy/utils/LocalDevServerState";
import sessionStorage, { SessionStorageKey } from "legacy/utils/sessionStorage";
import { extractIsDevModeFromSearchString } from "legacy/widgets/CustomWidget/devModeQueryParamUtils";
import {
  SetCurrentBranchPayload,
  fetchApplicationSuccess,
} from "store/slices/application/applicationActions";
import { createImmerReducer } from "../createReducer";

const initialState: EditorReduxState = {
  initialized: false,
  layout: {
    isLeftPanePinned:
      sessionStorage.getBoolean(SessionStorageKey.LEFT_PANE_PINNED) ?? false,
  },
  localDevModeEnabled: extractIsDevModeFromSearchString(window.location.search),
  branch: undefined,
  localDevLastHotReloadTime: null,
  localDevServerState: {
    status: LocalDevServerStatus.UNKNOWN,
  },
  showCustomComponentModal: false,
  loadingStates: {
    publishing: false,
    publishingError: false,
    dirty: false,
    saving: false,
    savingError: false,
    staleUpdateError: false,
    loading: false,
    loadingError: false,
    pageSwitchingError: false,
    isPageSwitching: false,
    creatingPage: false,
    creatingPageError: false,
    cloningPage: false,
    cloningPageError: false,
    savingFailuresCount: 0,
  },
  widgetOperations: {},
  deleteRouteConfirmationModal: {
    show: false,
  },
};

const editorReducer = createImmerReducer(initialState, (builder) => {
  builder
    // TODO(wylie): Use getSagaEvents helper once that doesn't cause a circular dependency
    .addCase("initializeEditorSaga/success", (state: EditorReduxState) => {
      state.initialized = true;
    })
    .addCase("initializeEditorSaga/error", (state: EditorReduxState) => {
      state.initialized = true;
    })
    .addCase(stopEvaluation.type, () => ({
      ...initialState,
      localDevModeEnabled: false,
    }))
    .addCase(ReduxActionTypes.FETCH_PAGE_INIT, (state: EditorReduxState) => {
      state.loadingStates.isPageSwitching = true;
      state.loadingStates.pageSwitchingError = false;
    })
    .addCase(ReduxActionTypes.FETCH_PAGE_SUCCESS, (state: EditorReduxState) => {
      state.loadingStates.isPageSwitching = false;
    })
    .addCase(
      ReduxActionErrorTypes.FETCH_PUBLISHED_PAGE_ERROR,
      (state: EditorReduxState) => {
        state.loadingStates.isPageSwitching = false;
        state.loadingStates.pageSwitchingError = true;
      },
    )
    .addCase(
      ReduxActionErrorTypes.FETCH_PAGE_ERROR,
      (state: EditorReduxState) => {
        state.loadingStates.isPageSwitching = false;
      },
    )
    .addCase(requestPageSave.type, (state: EditorReduxState) => {
      state.loadingStates.dirty = true;
      state.loadingStates.saving = true;
      state.loadingStates.dirty = false;
      state.loadingStates.savingError = false;
      state.loadingStates.staleUpdateError = false;
    })
    .addCase(savePageSuccess.type, (state: EditorReduxState) => {
      state.loadingStates.savingFailuresCount = 0;
      state.loadingStates.saving = false;
    })
    .addCase(
      ReduxActionErrorTypes.SAVE_PAGE_ERROR,
      (state: EditorReduxState, action: ReduxAction<{ error: any }>) => {
        state.loadingStates.savingFailuresCount =
          (state.loadingStates.savingFailuresCount ?? 0) + 1;
        state.loadingStates.saving = false;
        state.loadingStates.dirty = true;
        state.loadingStates.savingError = true;
        state.loadingStates.staleUpdateError =
          action.payload.error.code === API_STATUS_CODES.RESOURCE_CONFLICT;
      },
    )
    .addCase(
      ReduxActionTypes.SET_PAGE_DSL_VERSION,
      (
        state: EditorReduxState,
        action: ReduxAction<
          { version?: number },
          typeof ReduxActionTypes.SET_PAGE_DSL_VERSION
        >,
      ) => {
        state.currentPageVersion = action.payload.version;
      },
    )
    .addCase(initCanvasLayout, (state: EditorReduxState, action) => {
      state.loadingStates.publishing = false;
      state.loadingStates.publishingError = false;

      state.currentLayoutId = action.payload.currentLayoutId;
      state.pageWidgetId = action.payload.pageWidgetId;
      state.currentApplicationId = action.payload.currentApplicationId;
      state.currentlyEditedPageId = action.payload.currentPageId;
    })
    .addCase(createPageInit, (state: EditorReduxState) => {
      state.loadingStates.creatingPage = true;
      state.loadingStates.creatingPageError = false;
    })
    .addCase(
      ReduxActionErrorTypes.CREATE_PAGE_ERROR,
      (state: EditorReduxState) => {
        state.loadingStates.creatingPageError = true;
        state.loadingStates.creatingPage = false;
      },
    )
    .addCase(createPageSuccess, (state: EditorReduxState) => {
      state.loadingStates.creatingPage = false;
    })
    .addCase(duplicatePageInit, (state: EditorReduxState) => {
      state.loadingStates.creatingPage = false;
    })
    .addCase(ReduxActionTypes.PIN_LEFT_PANE, (state: EditorReduxState) => {
      state.layout.isLeftPanePinned = true;
    })
    .addCase(ReduxActionTypes.UNPIN_LEFT_PANE, (state: EditorReduxState) => {
      state.layout.isLeftPanePinned = false;
    })
    .addCase(
      ReduxActionTypes.SET_LOCAL_DEV_MODE,
      (
        state,
        action: ReduxAction<
          boolean,
          typeof ReduxActionTypes.SET_LOCAL_DEV_MODE
        >,
      ) => {
        state.localDevModeEnabled = action.payload;
      },
    )
    .addCase(ReduxActionTypes.SET_LAST_HOT_RELOAD_TIME, (state) => {
      state.localDevLastHotReloadTime = moment().format("HH:mm:ss");
    })
    .addCase(
      ReduxActionTypes.SET_LOCAL_DEV_SERVER_STATUS,
      (
        state,
        action: ReduxAction<
          LocalDevServerState,
          typeof ReduxActionTypes.SET_LOCAL_DEV_SERVER_STATUS
        >,
      ) => {
        state.localDevServerState = action.payload;
      },
    )
    .addCase(
      ReduxActionTypes.SET_SHOW_CUSTOM_COMPONENTS_MODAL,
      (
        state,
        action: ReduxAction<
          boolean,
          typeof ReduxActionTypes.SET_SHOW_CUSTOM_COMPONENTS_MODAL
        >,
      ) => {
        state.showCustomComponentModal = action.payload;
      },
    )
    .addCase(
      ReduxActionErrorTypes.CREATE_COMMIT_ERROR,
      (state: EditorReduxState, action: ReduxAction<{ error: any }>) => {
        state.loadingStates.staleUpdateError =
          action.payload.error.code === API_STATUS_CODES.RESOURCE_CONFLICT;
      },
    )
    .addCase(fetchApplicationSuccess, (state: EditorReduxState, action) => {
      if (action.payload.repoConnection) {
        state.branch = action.payload?.currentBranch;
      }
    })
    .addCase(
      ReduxActionTypes.SET_CURRENT_BRANCH,
      (
        state: EditorReduxState,
        action: ReduxAction<
          SetCurrentBranchPayload,
          typeof ReduxActionTypes.SET_CURRENT_BRANCH
        >,
      ) => {
        state.branch = action.payload.branch;
      },
    )
    .addCase(
      ReduxActionTypes.WIDGET_OPERATION_COMPLETE,
      (state: EditorReduxState) => {
        state.widgetOperations.runningOperation = undefined;
      },
    )
    .addCase(
      ReduxActionTypes.EDITOR_PROMPT_ROUTE_PARAMS,
      (
        state: EditorReduxState,
        action: ReduxAction<
          {
            id: string;
            path: string;
            testParams: Record<string, string>;
          },
          typeof ReduxActionTypes.EDITOR_PROMPT_ROUTE_PARAMS
        >,
      ) => {
        state.showRouteSelectorModal = action.payload;
      },
    )
    .addCase(
      ReduxActionTypes.EDITOR_PROMPT_ROUTE_PARAMS_CLOSE,
      (state: EditorReduxState) => {
        delete state.showRouteSelectorModal;
      },
    )
    .addCase(
      ReduxActionTypes.SET_DELETE_ROUTE_CONFIRMATION_MODAL,
      (
        state,
        action: ReduxAction<
          { show: boolean; routeId?: string },
          typeof ReduxActionTypes.SET_DELETE_ROUTE_CONFIRMATION_MODAL
        >,
      ) => {
        state.deleteRouteConfirmationModal = action.payload;
      },
    );

  WidgetOperationsWithCompletionListeners.forEach((actionType) => {
    builder.addCase(
      actionType,
      (
        state: EditorReduxState,
        action: ReduxAction<unknown, typeof actionType>,
      ) => {
        state.widgetOperations.runningOperation = action.type;
      },
    );
  });
});

export interface EditorReduxState {
  initialized: boolean;
  pageWidgetId?: string;
  currentLayoutId?: string;
  /** This is the id of the page that is currently rendered in the editor */
  currentlyEditedPageId?: string;
  currentPageVersion?: number;
  currentApplicationId?: string;
  //TODO: move this to editor preference
  layout: {
    isLeftPanePinned: boolean;
  };
  localDevModeEnabled: boolean;
  branch: BranchDto | undefined;
  localDevLastHotReloadTime: string | null;
  localDevServerState: LocalDevServerState;
  showCustomComponentModal: boolean;
  showRouteSelectorModal?: {
    id: string;
    path: string;
    testParams: Record<string, string>;
  };
  loadingStates: {
    dirty: boolean;
    saving: boolean;
    savingError: boolean;
    staleUpdateError: boolean;
    publishing: boolean;
    published?: string;
    publishingError: boolean;
    loading: boolean;
    loadingError: boolean;
    isPageSwitching: boolean;
    pageSwitchingError: boolean;
    creatingPage: boolean;
    creatingPageError: boolean;
    cloningPage: boolean;
    cloningPageError: boolean;
    savingFailuresCount: number;
  };
  widgetOperations: {
    runningOperation?: string;
  };
  deleteRouteConfirmationModal: {
    show: boolean;
    routeId?: string;
  };
}

export default editorReducer;
