import {
  BaseFormItem,
  FormItem,
  InputDataType,
  FormSection,
} from "@superblocksteam/shared";
import { HelpContent } from "store/slices/apisV2/control-flow/types";

/* eslint-disable import/no-unused-modules */

export enum TabBehaviour {
  INPUT = "INPUT",
  INDENT = "INDENT",
}

export enum EditorSize {
  COMPACT = "COMPACT",
  EXTENDED = "EXTENDED",
}

export type ControlInputDataType = InputDataType & {
  size?: EditorSize;
  borderLess?: boolean;
  showLineNumbers?: boolean;
  noNewlines?: boolean;
  tabBehaviour?: TabBehaviour;
  enableSearch?: boolean;
  isExpression?: boolean;
  lineWrapping?: boolean;
};

export type ControlFlowFormItem = (
  | FormItem
  | ExpressionEditorListFormItem
  | ExpressionEditorFormItem
  | LocalVariablesListFormItem
  | DynamicVariablesListFormItem
  | NumberInputFormItem
  | UniqueListInputFormItem
) & {
  controlFlowTooltip?: HelpContent;
};

export enum ControlFlowFormComponentType {
  EXPRESSION_EDITOR_LIST = "EXPRESSION_EDITOR_LIST",
  EXPRESSION_EDITOR = "EXPRESSION_EDITOR",
  LOCAL_VARIABLES_LIST = "LOCAL_VARIABLES_LIST",
  VARIABLES_LIST = "VARIABLES_LIST",
  NUMBER_INPUT = "NUMBER_INPUT",
  UNIQUE_LIST_INPUT = "UNIQUE_LIST_INPUT",
}

export { FormComponentType } from "@superblocksteam/shared";
export type { FormItem } from "@superblocksteam/shared";

export type ControlFlowBaseFormItem = BaseFormItem & {
  expectedValue?: string;
  exampleData?: any;
};

export interface UniqueListInputFormItem extends ControlFlowBaseFormItem {
  componentType: ControlFlowFormComponentType.UNIQUE_LIST_INPUT;
  placeholder?: string;
  addLabel?: string;
  label: string;
  itemTypeText?: string;
  getAddedName?: (idx: number) => string;
  getShowConfirmOnDelete?: (idx: number) => boolean;
  confirmTitle?: string;
}

export interface ExpressionEditorFormItem extends ControlFlowBaseFormItem {
  componentType: ControlFlowFormComponentType.EXPRESSION_EDITOR;
  placeholder?: string;
}

export interface ExpressionEditorListFormItem extends ControlFlowBaseFormItem {
  componentType: ControlFlowFormComponentType.EXPRESSION_EDITOR_LIST;
  placeholder?: string;
  dataType?: ControlInputDataType;
  subtitle?: string;
  valuePath?: string;
  addLabel?: string;
  getShowConfirmOnDelete?: (idx: number) => boolean;
  confirmTitle?: string;
}

export interface DynamicVariablesListFormItem extends ControlFlowBaseFormItem {
  componentType: ControlFlowFormComponentType.VARIABLES_LIST;
  placeholder?: string;
  dataType?: ControlInputDataType;
  subtitle?: string;
  addLabel?: string;
}

export interface LocalVariablesListFormItem extends ControlFlowBaseFormItem {
  componentType: ControlFlowFormComponentType.LOCAL_VARIABLES_LIST;
  placeholder?: string;
  subHeading?: string;
  variables?: Record<string, string>;
  varNameOverrides?: Record<string, string>;
}

export interface NumberInputFormItem extends ControlFlowBaseFormItem {
  componentType: ControlFlowFormComponentType.NUMBER_INPUT;
  placeholder?: string;
  subHeading?: string;
}

export interface Variable {
  name: string;
  value: string | number | boolean | any[] | Record<string, any>;
  editable?: boolean;
}

export interface ControlFlowFormTemplate {
  sections: ControlFlowFormSection[];
}

export type FormSectionMeta = Omit<FormSection, "items">;

export interface ControlFlowFormSection extends FormSectionMeta {
  items: ControlFlowFormItem[];
}
