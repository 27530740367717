import { ActionType } from "@superblocksteam/shared";
import { ActionConfigurationDto } from "store/slices/apis/types";
import * as BackendTypes from "../backend-types";
import {
  GenericBlock,
  BreakControl,
  ReturnControl,
  WaitControl,
  ParallelControl,
  ConditionControl,
  LoopControl,
  LoopType,
  TryCatchControl,
  VariablesControl,
  ParallelWait,
  StepConfig,
  ThrowControl,
  BlockType,
  SendControl,
  StreamControl,
} from "./types";

type BackendBlockTypeEnum = Exclude<keyof BackendTypes.Block, "name">;

type MappedExtractionTypes = {
  [k in BackendBlockTypeEnum]: [k, NonNullable<BackendTypes.Block[k]>];
};
// AKA ["break", BreakConfig] | ["return", ReturnConfig] | ...
type MappedExtractionUnion = MappedExtractionTypes[BackendBlockTypeEnum];

const ALL_BLOCK_TYPES = [
  "break",
  "return",
  "wait",
  "throw",
  "parallel",
  "conditional",
  "loop",
  "tryCatch",
  "step",
  "variables",
  "send",
  "stream",
] as const;

// Below types enforce that ALL_BLOCK_TYPES contains all block types and nothing extra
// eslint-disable-next-line @typescript-eslint/no-unused-vars
type AssertThatArrHasAllEnumValues<
  T extends (typeof ALL_BLOCK_TYPES)[number] = BackendBlockTypeEnum,
> = T;
// eslint-disable-next-line @typescript-eslint/no-unused-vars
type AssertThatArrHasNoExtraValues<
  T extends BackendBlockTypeEnum = (typeof ALL_BLOCK_TYPES)[number],
> = T;

const extractConfig = (
  backendBlock: BackendTypes.Block,
): MappedExtractionUnion => {
  for (const blockType of ALL_BLOCK_TYPES) {
    if (blockType in backendBlock) {
      const config = backendBlock[blockType];
      if (config != null) {
        return [blockType, config as any];
      }
    }
  }
  throw new Error("Invalid block type detected");
};

export type ControlFlowPluginInfo = {
  pluginNames: Set<string>;
  datasourceLookup: Record<string, string>;
};

export const convertBlockToFrontend = (
  backendBlock: BackendTypes.Block,
  metadata: BackendTypes.Api["metadata"],
  pluginInfo: ControlFlowPluginInfo,
): [convertedBlock: GenericBlock, reportedChildren?: BackendTypes.Block[]] => {
  const { pluginNames, datasourceLookup } = pluginInfo;
  const baseBlock = {
    name: backendBlock.name,
  };
  const reportedChildren: BackendTypes.Block[] = [];
  const convertAndAddBlocks = (blocks: BackendTypes.Block[]) => {
    return blocks.map((block) => {
      reportedChildren.push(block);
      return block.name;
    });
  };

  const [type, config] = extractConfig(backendBlock);
  switch (type) {
    case "break": {
      const controlConfig: BreakControl = {
        condition: config.condition,
      };
      return [
        {
          ...baseBlock,
          type: BlockType.BREAK,
          config: controlConfig,
        },
      ];
    }
    case "return": {
      const controlConfig: ReturnControl = {
        data: config.data,
      };
      return [
        {
          ...baseBlock,
          type: BlockType.RETURN,
          config: controlConfig,
        },
      ];
    }
    case "wait": {
      const controlConfig: WaitControl = {
        condition: config.condition,
      };
      return [
        {
          ...baseBlock,
          type: BlockType.WAIT,
          config: controlConfig,
        },
      ];
    }
    case "throw": {
      const controlConfig: ThrowControl = {
        error: config.error,
      };
      return [
        {
          ...baseBlock,
          type: BlockType.THROW,
          config: controlConfig,
        },
      ];
    }
    case "parallel": {
      const convertedWait = {
        [BackendTypes.WAIT_NUM.WAIT_ALL]: ParallelWait.WAIT_ALL,
        [BackendTypes.WAIT_NUM.WAIT_NONE]: ParallelWait.WAIT_NONE,
        [BackendTypes.WAIT_NUM.WAIT_UNSPECIFIED]: ParallelWait.WAIT_UNSPECIFIED,
        [BackendTypes.WAIT_STR.WAIT_ALL]: ParallelWait.WAIT_ALL,
        [BackendTypes.WAIT_STR.WAIT_NONE]: ParallelWait.WAIT_NONE,
        [BackendTypes.WAIT_STR.WAIT_UNSPECIFIED]: ParallelWait.WAIT_UNSPECIFIED,
      }[config.wait];

      const controlConfig: ParallelControl = {
        wait: convertedWait,
        poolSize: config.poolSize,
      };
      if (config.dynamic != null) {
        controlConfig.dynamic = {
          blocks: convertAndAddBlocks(config.dynamic.blocks ?? []),
          variables: {
            ...config.dynamic.variables,
          },
          paths: config.dynamic.paths,
        };
      } else if (config.static != null) {
        const paths = config.static.paths ?? {};
        controlConfig.static = {
          paths: Object.keys(paths).reduce(
            (accum: Record<string, string[]>, pathName) => {
              accum[pathName] = convertAndAddBlocks(
                paths[pathName].blocks ?? [],
              );
              return accum;
            },
            {},
          ),
        };
      }

      const convertedBlock: GenericBlock = {
        ...baseBlock,
        type: BlockType.PARALLEL,
        config: controlConfig,
      };
      return [convertedBlock, reportedChildren];
    }
    case "conditional": {
      const controlConfig: ConditionControl = {
        if: config.if
          ? {
              condition: config.if.condition,
              blocks: convertAndAddBlocks(config.if.blocks ?? []),
            }
          : {
              condition: "{{}}",
              blocks: [],
            },
        elseIf:
          config.elseIf &&
          config.elseIf.map((cond) => ({
            condition: cond.condition,
            blocks: convertAndAddBlocks(cond.blocks ?? []),
          })),
        else: config.else?.blocks && convertAndAddBlocks(config.else.blocks),
      };
      const convertedBlock: GenericBlock = {
        ...baseBlock,
        type: BlockType.CONDITION,
        config: controlConfig,
      };
      return [convertedBlock, reportedChildren];
    }
    case "loop": {
      const convertedType = {
        [BackendTypes.LOOP_TYPE.UNSPECIFIED]: LoopType.UNSPECIFIED,
        [BackendTypes.LOOP_TYPE.FOR]: LoopType.FOR,
        [BackendTypes.LOOP_TYPE.FOREACH]: LoopType.FOREACH,
        [BackendTypes.LOOP_TYPE.WHILE]: LoopType.WHILE,
      }[config.type];

      const controlConfig: LoopControl = {
        type: convertedType,
        range: config.range,
        blocks: convertAndAddBlocks(config.blocks ?? []),
        variables: config.variables,
      } as LoopControl;
      const convertedBlock: GenericBlock = {
        ...baseBlock,
        type: BlockType.LOOP,
        config: controlConfig,
      };
      return [convertedBlock, reportedChildren];
    }
    case "tryCatch": {
      const controlConfig: TryCatchControl = {
        try: convertAndAddBlocks(config.try?.blocks ?? []),
        catch: convertAndAddBlocks(config.catch?.blocks ?? []),
        finally:
          config.finally?.blocks && convertAndAddBlocks(config.finally.blocks),
        variables: config.variables,
      } as TryCatchControl;

      const convertedBlock: GenericBlock = {
        ...baseBlock,
        type: BlockType.TRY_CATCH,
        config: controlConfig,
      };
      return [convertedBlock, reportedChildren];
    }
    case "step": {
      const pluginKey = Object.keys(config).find((potentialPluginName) =>
        pluginNames.has(potentialPluginName),
      );
      const pluginConfig = pluginKey == null ? {} : config[pluginKey];
      const stepConfig: StepConfig = {
        type: ActionType.Integration,
        name: backendBlock.name,
        id: backendBlock.name,
        datasourceId:
          config.integration ?? (pluginKey && datasourceLookup[pluginKey]),
        organizationId: metadata.organization,
        pluginId: pluginKey ?? "unknown",
        configuration: pluginConfig as ActionConfigurationDto,
      };
      return [
        {
          ...baseBlock,
          type: BlockType.STEP,
          config: stepConfig,
        },
      ];
    }
    case "variables": {
      const controlConfig: VariablesControl = {
        variables: config.items,
      };
      return [
        {
          ...baseBlock,
          type: BlockType.VARIABLES,
          config: controlConfig,
        },
      ];
    }
    case "send": {
      const controlConfig: SendControl = {
        message: config.message,
      };
      return [
        {
          ...baseBlock,
          type: BlockType.SEND,
          config: controlConfig,
        },
      ];
    }
    case "stream": {
      const controlConfig: StreamControl = {
        trigger: convertAndAddBlocks(config?.trigger ? [config?.trigger] : []),
        process: config?.process?.blocks
          ? convertAndAddBlocks(config?.process?.blocks)
          : undefined,
        variables: config.variables,
        autoSend: config.options?.disable_auto_send !== true,
      };
      const convertedBlock: GenericBlock = {
        ...baseBlock,
        type: BlockType.STREAM,
        config: controlConfig,
      };
      return [convertedBlock, reportedChildren];
    }
    default: {
      const exhaustiveCheck: never = type;
      throw new Error(`Unhandled type: ${exhaustiveCheck}`);
    }
  }
};
