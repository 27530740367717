import equal from "@superblocksteam/fast-deep-equal";
import {
  Agent,
  ApplicationConfiguration,
  ApplicationScope,
  createTriggerStepRenamer,
  getUpdatesInWidgets,
  ModifyEntitiesPayloadApi,
  PropertyUpdate,
  renameInApplicationConfiguration as performRenameInApplicationConfiguration,
} from "@superblocksteam/shared";
import { get, isArray, isEqual, set } from "lodash";
import { all, call, put, select, take } from "redux-saga/effects";

import {
  renameWidgets,
  RenameEmbedPropUpdate,
  renameEmbedProps,
  updateWidgetProperties,
} from "legacy/actions/controlActions";
import { requestPageSave, savePageSuccess } from "legacy/actions/pageActions";
import {
  connectToISocketRPCServer,
  StdISocketRPCClient,
} from "legacy/api/ISocketRPC";
import {
  StepDef,
  isValidStepDef,
  ValidMultiStepDef,
} from "legacy/constants/ActionConstants";
import { ERROR_CODES } from "legacy/constants/ApiConstants";
import { EmbedPropertyMap } from "legacy/constants/EmbeddingConstants";
import {
  ReduxActionErrorTypes,
  ReduxActionTypes,
} from "legacy/constants/ReduxActionConstants";
import { DataTree } from "legacy/entities/DataTree/dataTreeFactory";
import { getLayoutSavePayload } from "legacy/sagas/AppAndPageSavingSagas";
import { getAppProfilesInCurrentMode } from "legacy/selectors/applicationSelectors";
import { getDataTree } from "legacy/selectors/dataTreeSelectors";
import {
  getCurrentApplicationId,
  getCurrentBranch,
  getCurrentPageId,
} from "legacy/selectors/editorSelectors";
import { getIsTab } from "legacy/selectors/entitiesSelector";
import { getRoutes } from "legacy/selectors/routeSelectors";
import {
  getExistingPageNames,
  getExistingWidgetNames,
  getWidgets,
  getEmbedProperties,
  getAllEmbedPropNames,
  getWidget,
} from "legacy/selectors/sagaSelectors";
import selectLastSuccessfulWrite from "legacy/selectors/successfulWriteSelector";
import PerformanceTracker, {
  PerformanceTransactionName,
} from "legacy/utils/PerformanceTracker";
import { validateName } from "legacy/utils/helpers";
import { extractJsEvaluationPairs } from "legacy/workers/evaluationUtils";
import { selectAllApiUnionNames } from "store/slices/apisShared/selectors";
import { lock } from "store/slices/apisShared/sharedPersistApiLock";
import { getActiveAgents } from "store/slices/apisShared/utils";
import { getAllV2ApisSaga } from "store/slices/apisV2";
import {
  getUpdatedApi,
  refactorNameInV2ApiSaga,
} from "store/slices/apisV2/sagas/refactorNameInV2Api";
import { selectAllV2Apis } from "store/slices/apisV2/selectors";
import { ApiDtoWithPb } from "store/slices/apisV2/slice";
import { doneRefactoringAppConfiguration } from "store/slices/application/applicationActions";
import {
  renameCustomEvents,
  RenameCustomEventUpdate,
} from "store/slices/application/events/eventActions";
import { getScopedEvents } from "store/slices/application/events/selectors";
import {
  getEnvironment,
  getMergedAppConfiguration,
} from "store/slices/application/selectors";
import {
  getAllStateVars,
  getScopedStateVarNames,
} from "store/slices/application/stateVars/selectors";
import { renameStateVars } from "store/slices/application/stateVars/slice";
import {
  getAllTimers,
  getScopedTimerNames,
  getScopedTimers,
} from "store/slices/application/timers/selectors";
import {
  renameTimers,
  restartTimers,
} from "store/slices/application/timers/timerActions";
import { Flag, selectFlagById } from "store/slices/featureFlags";
import { selectOnlyOrganization } from "store/slices/organizations";
import { orgIsOnPremise } from "store/slices/organizations/utils";
import { GeneratorReturnType } from "store/utils/types";
import { fastClone } from "utils/clone";
import { sendErrorUINotification } from "utils/notification";
import { getShouldSignAndVerify } from "utils/resource-signing";
import {
  createFakeDataTree,
  ScopedFakeDataTree,
  refactorNameInValue,
} from "../helpers/refactoring";
import {
  ApiV1,
  refactorV1NameSaga,
  selectAllV1Apis,
} from "../slices/apisShared";
import { PayloadAction } from "../utils/action";
import { callSagas, createSaga } from "../utils/saga";

import type { WidgetMap } from "legacy/widgets";
import type { TabsWidgetProps } from "legacy/widgets/TabsWidget/types";

export interface RenameApplicationEntityPayload {
  entityId: string;
  oldName: string;
  newName: string;
  scope: ApplicationScope;
  namespace?: string;
  skipSave?: boolean;
}

export function* refactorNameInWidgets(
  oldName: string,
  newName: string,
  dataTree: ScopedFakeDataTree,
  scope = ApplicationScope.PAGE,
  namespace?: string,
): Generator<any, any, any> {
  const widgets: WidgetMap = yield select(getWidgets);

  PerformanceTracker.startAsyncTracking(
    PerformanceTransactionName.RENAME_WIDGETS,
  );
  const updates: Array<PropertyUpdate> = yield getUpdatesInWidgets({
    widgets,
    oldName,
    newName,
    dataTree: dataTree[scope],
    extractPairs: extractJsEvaluationPairs,
    namespace,
  });

  const widgetUpdates = updates.map((update) => ({
    propertyName: update.propertyName,
    propertyValue: update.propertyValue,
    widgetId: update.entityId,
  }));

  if (updates.length) {
    yield put(
      renameWidgets(
        widgetUpdates,
        false /* savePage */,
        true /* clearReplayStack */,
      ),
    );
  }

  PerformanceTracker.stopAsyncTracking(
    PerformanceTransactionName.RENAME_WIDGETS,
  );
}

export function* refactorNameInStateVars(
  oldName: string,
  newName: string,
  dataTree: ScopedFakeDataTree,
  scope: ApplicationScope,
  namespace?: string,
): Generator<any, any, any> {
  // We use all state vars because you could be referencing an app scoped or page scoped entity
  const stateVars: ReturnType<typeof getAllStateVars> = yield select(
    getAllStateVars,
  );
  const updates: Parameters<typeof renameStateVars>[0] = [];

  for (const [id, stateVar] of Object.entries(stateVars)) {
    if (stateVar.dynamicBindingPathList) {
      yield all(
        stateVar.dynamicBindingPathList.map(async ({ key }) => {
          const binding = get(stateVar, key);
          const withNewName = await refactorNameInValue({
            oldValue: binding,
            oldName,
            newName,
            dataTree: dataTree[scope],
            namespace,
            extractPairs: extractJsEvaluationPairs,
          });
          if (!isEqual(binding, withNewName)) {
            updates.push({
              stateVarId: id,
              scope: stateVar.scope,
              propertyName: key,
              propertyValue: withNewName,
            });
          }
        }),
      );
    }

    if (stateVar.name === oldName && stateVar.scope === scope) {
      updates.push({
        stateVarId: id,
        scope: stateVar.scope,
        propertyName: "name",
        propertyValue: newName,
      });
    }
  }

  if (updates.length) {
    yield put(renameStateVars(updates));
  }
}

export function* refactorNameInEmbedProps(
  oldName: string,
  newName: string,
  dataTree: ScopedFakeDataTree,
  scope = ApplicationScope.PAGE,
  namespace?: string,
): Generator<any, any, any> {
  const embedProps: EmbedPropertyMap = yield select(getEmbedProperties);
  const updates: Array<RenameEmbedPropUpdate> = [];

  for (const [id, embedProp] of Object.entries(embedProps)) {
    const renameInTriggerStep = createTriggerStepRenamer({
      oldName,
      newName,
      dataTree: dataTree[scope],
      addUpdate: (payload) => {
        updates.push({
          ...payload,
          embedPropId: id,
        });
      },
      extractEvaluationPairs: extractJsEvaluationPairs,
      namespace,
    });

    if (embedProp.onChange) {
      yield all(
        embedProp?.onChange?.map(async (step, index) => {
          if (isValidStepDef(step)) {
            await renameInTriggerStep(step, "onChange", index);
          }
          return null;
        }),
      );
    }

    if (embedProp.dynamicBindingPathList) {
      yield all(
        embedProp.dynamicBindingPathList.map(async ({ key }) => {
          const binding = get(embedProp, key);
          const withNewName = await refactorNameInValue({
            oldValue: binding,
            oldName,
            newName,
            dataTree: dataTree[scope],
            namespace,
            extractPairs: extractJsEvaluationPairs,
          });
          if (!isEqual(binding, withNewName)) {
            updates.push({
              embedPropId: id,
              propertyName: key,
              propertyValue: withNewName,
            });
          }
        }),
      );
    }

    // TODO(APP_SCOPE): Check for scope as well
    if (embedProp.name === oldName) {
      updates.push({
        embedPropId: id,
        propertyName: "name",
        propertyValue: newName,
      });
    }
  }
  if (updates.length) {
    yield put(renameEmbedProps(updates));
  }
}

export function* refactorNameInCustomEvents(
  oldName: string,
  newName: string,
  dataTree: ScopedFakeDataTree,
  scope = ApplicationScope.PAGE,
  namespace?: string,
): Generator<any, any, any> {
  const events: ReturnType<typeof getScopedEvents> = yield select(
    getScopedEvents,
    scope,
  );
  const updates: Array<RenameCustomEventUpdate> = [];

  for (const [id, event] of Object.entries(events)) {
    const renameInTriggerStep = createTriggerStepRenamer({
      oldName,
      newName,
      dataTree: dataTree[scope],
      addUpdate: (payload) => {
        updates.push({
          ...payload,
          eventId: id,
          scope: event.scope,
        });
      },
      extractEvaluationPairs: extractJsEvaluationPairs,
      namespace,
    });

    if (event.onTrigger) {
      yield all(
        event?.onTrigger?.map(async (step, index) => {
          if (isValidStepDef(step)) {
            await renameInTriggerStep(step, "onTrigger", index);
          }
          return null;
        }),
      );
    }

    if (event.name === oldName) {
      updates.push({
        eventId: id,
        propertyName: "name",
        propertyValue: newName,
        scope: event.scope,
      });
    }
  }

  if (updates.length) {
    yield put(renameCustomEvents(updates));
  }
}

export function* refactorNameInTimers(
  oldName: string,
  newName: string,
  dataTree: ScopedFakeDataTree,
  scope: ApplicationScope,
  namespace?: string,
): Generator<any, any, any> {
  const timers: ReturnType<typeof getAllTimers> = yield select(
    getScopedTimers,
    ApplicationScope.PAGE,
  );
  const updates: Array<{
    timerId: string;
    propertyName: string;
    propertyValue: unknown;
    scope: ApplicationScope;
  }> = [];
  const timersToRestart = new Set<string>();

  for (const [timerId, timer] of Object.entries(timers)) {
    if (timer.name === oldName && timer.scope === scope) {
      updates.push({
        timerId,
        scope: timer.scope,
        propertyName: "name",
        propertyValue: newName,
      });
    }

    if (timer.dynamicTriggerPathList) {
      const renameInTriggerStep = createTriggerStepRenamer({
        oldName,
        newName,
        dataTree: dataTree[scope],
        addUpdate: (payload) => {
          updates.push({
            ...payload,
            timerId,
            scope: timer.scope,
          });

          timersToRestart.add(timerId);
        },
        extractEvaluationPairs: extractJsEvaluationPairs,
        namespace,
      });
      yield all(
        timer.dynamicTriggerPathList.map(async ({ key }) => {
          const trigger: ValidMultiStepDef | undefined = get(timer, key);
          if (!isArray(trigger)) {
            return;
          }
          await Promise.all(
            trigger.map(async (step: StepDef, index) => {
              if (!isValidStepDef(step)) return;
              await renameInTriggerStep(step, key, index);
            }),
          );
        }),
      );
    }
  }

  yield all([
    updates.length > 0 ? put(renameTimers(updates)) : undefined,
    timersToRestart.size > 0
      ? put(restartTimers(Array.from(timersToRestart)))
      : undefined,
  ]);
}

function* refactorNameInRoutes(
  oldName: string,
  newName: string,
  dataTree: ScopedFakeDataTree,
  scope = ApplicationScope.PAGE,
  namespace?: string,
) {
  const routes: ReturnType<typeof getRoutes> = yield select(getRoutes);

  const updatedRoutes: typeof routes = {};

  for (const [id, route] of Object.entries(routes)) {
    const renameInTriggerStep = createTriggerStepRenamer({
      oldName,
      newName,
      dataTree: dataTree[scope],
      addUpdate: (payload) => {
        // use fastClone because route is read-only
        const newValue = set(
          fastClone(route),
          payload.propertyName,
          payload.propertyValue,
        );
        updatedRoutes[id] = newValue;
      },
      extractEvaluationPairs: extractJsEvaluationPairs,
      namespace,
    });

    if (route.dynamicTriggerPathList) {
      yield all(
        route.dynamicTriggerPathList.map(async ({ key }) => {
          const trigger: ValidMultiStepDef | undefined = get(route, key);
          if (!isArray(trigger)) {
            return;
          }
          await Promise.all(
            trigger.map(async (step: StepDef, index) => {
              if (!isValidStepDef(step)) return;
              await renameInTriggerStep(step, key, index);
            }),
          );
        }),
      );
    }
  }

  if (Object.values(updatedRoutes).length) {
    yield put({
      type: ReduxActionTypes.UPDATE_ROUTE_PROPERTIES,
      payload: updatedRoutes,
    });
  }
}

function* refactorNameInCurrentLayout(
  oldName: string,
  newName: string,
  namespace?: string,
  scope = ApplicationScope.PAGE,
) {
  const dataTree: DataTree = yield select(getDataTree);
  const fakeDataTree: ReturnType<typeof createFakeDataTree> = yield call(
    createFakeDataTree,
    dataTree,
  );

  yield all([
    call(
      refactorNameInWidgets,
      oldName,
      newName,
      fakeDataTree,
      scope,
      namespace,
    ),
    call(
      refactorNameInTimers,
      oldName,
      newName,
      fakeDataTree,
      scope,
      namespace,
    ),
    call(
      refactorNameInStateVars,
      oldName,
      newName,
      fakeDataTree,
      scope,
      namespace,
    ),
    call(
      refactorNameInEmbedProps,
      oldName,
      newName,
      fakeDataTree,
      scope,
      namespace,
    ),
    call(
      refactorNameInCustomEvents,
      oldName,
      newName,
      fakeDataTree,
      scope,
      namespace,
    ),
    call(
      refactorNameInRoutes,
      oldName,
      newName,
      fakeDataTree,
      scope,
      namespace,
    ),
  ]);

  if (scope === ApplicationScope.PAGE) {
    yield put(requestPageSave());
    const result: PayloadAction<unknown> = yield take([
      savePageSuccess.type,
      ReduxActionErrorTypes.SAVE_PAGE_ERROR,
    ]);

    if (result.type === ReduxActionErrorTypes.SAVE_PAGE_ERROR) {
      throw result.payload;
    }
  }
}

function* refactorNameInCurrentV1Apis(
  oldName: string,
  newName: string,
  namespace?: string,
  skipSave = false,
) {
  const apis: Record<string, ApiV1> = yield select(selectAllV1Apis);

  const refactorSagas = Object.keys(apis).map((apiId) =>
    refactorV1NameSaga.apply({
      apiId,
      oldName,
      newName,
      namespace,
    }),
  );

  yield* callSagas(refactorSagas);
}

function* refactorNameInCurrentV2Apis(
  oldName: string,
  newName: string,
  scope: ApplicationScope,
  namespace?: string,
  skipSave = false,
): Generator<any, any, any> {
  const apis: ReturnType<typeof selectAllV2Apis> = yield select(
    selectAllV2Apis,
  );

  const refactorSagas = Object.keys(apis).map((apiId) =>
    refactorNameInV2ApiSaga.apply({
      apiId,
      oldName,
      newName,
      scope,
      namespace,
      skipSave,
    }),
  );

  yield* callSagas(refactorSagas);
}

function* getNameError(newName: string, scope: ApplicationScope) {
  const existingWidgetNames: string[] = yield select(getExistingWidgetNames);
  const existingPageNames: string[] = yield select(getExistingPageNames);
  const existingApiUnionNames: string[] = yield select(selectAllApiUnionNames);
  const existingTimerNames: string[] = yield select(getScopedTimerNames, scope);
  const existingStateVarNames: string[] = yield select(
    getScopedStateVarNames,
    scope,
  );
  const existingEmbedPropNames: string[] = yield select(getAllEmbedPropNames);

  const nameError = validateName(newName, [
    ...existingWidgetNames,
    ...existingPageNames,
    ...existingApiUnionNames,
    ...existingTimerNames,
    ...existingStateVarNames,
    ...existingEmbedPropNames,
  ]);

  return nameError;
}

function* renamePageEntitySaga({
  entityId,
  oldName,
  newName,
  namespace,
  scope = ApplicationScope.PAGE,
}: Omit<RenameApplicationEntityPayload, "entityKind">) {
  // Check if the entity is a tab
  // Tab names are not referable in the data tree
  const isTabWidget: boolean = yield select(getIsTab, entityId);
  if (isTabWidget) {
    const tab: ReturnType<typeof getWidget> = yield select(getWidget, entityId);
    const parent: TabsWidgetProps<any> = yield select(getWidget, tab.parentId);
    const newTabs = parent.tabs.map((t) =>
      t.widgetId === entityId ? { ...t, label: newName } : t,
    );
    // TODO(@aleiux): We could use to controlflow variable rename
    yield put(updateWidgetProperties(parent.widgetId, { tabs: newTabs }));
    if (parent.defaultTab === oldName) {
      yield put(
        updateWidgetProperties(parent.widgetId, { defaultTab: newName }),
      );
    }

    return;
  }

  const nameError: string = yield call(getNameError, newName, scope);
  if (nameError) {
    throw new Error(nameError);
  }

  yield all([
    call(refactorNameInCurrentLayout, oldName, newName, namespace, scope),
    // TODO(API_SCOPE): this needs tinkering when we have API scope
    ...(scope === ApplicationScope.PAGE
      ? [
          call(refactorNameInCurrentV1Apis, oldName, newName, namespace),
          call(refactorNameInCurrentV2Apis, oldName, newName, scope, namespace),
        ]
      : []),
  ]);
}

export function* renameInApplicationConfiguration({
  entityId,
  oldName,
  newName,
  configuration,
}: {
  entityId: string;
  newName: string;
  oldName: string;
  configuration: ApplicationConfiguration;
}): Generator<any, any, any> {
  const dataTree: DataTree = yield select(getDataTree);
  const fakeDataTree: ReturnType<typeof createFakeDataTree> = yield call(
    createFakeDataTree,
    dataTree,
  );

  const dsl: Awaited<
    ReturnType<typeof performRenameInApplicationConfiguration>
  > = yield call(performRenameInApplicationConfiguration, {
    dsl: configuration.dsl,
    oldName,
    newName,
    dataTree: fakeDataTree[ApplicationScope.APP],
    namespace: undefined,
    extractPairs: extractJsEvaluationPairs,
  });

  if (dsl) {
    configuration.dsl = dsl;
    yield put(doneRefactoringAppConfiguration(configuration));
  }

  return configuration;
}

/**
 * Performs a rename locally, returning only the APIs that have been updated.
 * This **does not** save the changes to the server. You are responsible for that.
 */
function* renameInAPIs({
  oldName,
  newName,
  scope,
  namespace,
}: {
  oldName: string;
  newName: string;
  scope: ApplicationScope;
  namespace?: string;
}): Generator<any, ApiDtoWithPb[], any> {
  const apis: ReturnType<typeof selectAllV2Apis> = yield select(
    selectAllV2Apis,
  );
  const updatedApis: GeneratorReturnType<typeof getUpdatedApi>[] = yield all(
    Object.entries(apis).map(([apiId, api]) =>
      call(getUpdatedApi, {
        api,
        apiId,
        newName,
        oldName,
        scope,
        namespace,
      }),
    ),
  );

  return updatedApis.filter((api) => {
    return !equal(api, apis[api.id]);
  });
}

function* renameApplicationEntitySaga({
  entityId,
  oldName,
  newName,
  scope = ApplicationScope.PAGE,
  namespace,
}: RenameApplicationEntityPayload) {
  if (scope === ApplicationScope.PAGE) {
    yield call(renamePageEntitySaga, {
      entityId,
      oldName,
      newName,
      namespace,
      scope,
    });
  } else {
    const nameError: string = yield call(
      getNameError,
      newName,
      ApplicationScope.APP,
    );

    if (nameError) {
      throw new Error(nameError);
    }

    let rpcClient: StdISocketRPCClient | undefined;
    const applicationId: string | undefined = yield select(
      getCurrentApplicationId,
    );

    if (!applicationId) {
      throw new Error("No applicationId found");
    }

    const unlock: Awaited<ReturnType<typeof lock>> = yield call(lock);

    const branch: ReturnType<typeof getCurrentBranch> = yield select(
      getCurrentBranch,
    );
    const lastSuccessfulWrite: ReturnType<typeof selectLastSuccessfulWrite> =
      yield select(selectLastSuccessfulWrite);

    const organization: ReturnType<typeof selectOnlyOrganization> =
      yield select(selectOnlyOrganization);
    const isOnPremise = orgIsOnPremise(organization);
    let agents: Agent[] = [];
    let isSigningRequired = false;
    if (isOnPremise) {
      const environment: ReturnType<typeof getEnvironment> = yield select(
        getEnvironment,
      );
      const profiles: ReturnType<typeof getAppProfilesInCurrentMode> =
        yield select(getAppProfilesInCurrentMode);
      const profile = profiles?.selected;
      const enableProfiles: boolean = yield select(
        selectFlagById,
        Flag.ENABLE_PROFILES,
      );
      try {
        agents = yield call(getActiveAgents, {
          organization,
          environment,
          enableProfiles,
          profile,
        });
        isSigningRequired = yield call(getShouldSignAndVerify, agents);

        if (isSigningRequired && agents.length === 0) {
          yield put({
            type: ReduxActionErrorTypes.UPDATE_APPLICATION_ERROR,
            payload: {
              error: new Error("No OPA agents found"),
            },
          });
          yield put({
            type: ReduxActionTypes.SAFE_CRASH_SUPERBLOCKS_REQUEST,
            payload: {
              code: ERROR_CODES.NO_AGENTS,
            },
          });
          return;
        }
      } catch (e) {
        sendErrorUINotification({
          message: "Failed to connect to OPA agents",
        });
        return;
      }
    }

    const currentPageId: ReturnType<typeof getCurrentPageId> = yield select(
      getCurrentPageId,
    );
    if (!currentPageId) {
      throw new Error("No current page");
    }

    const appConfiguration: ApplicationConfiguration = yield select(
      getMergedAppConfiguration,
    );

    const [, newConfiguration, newApis]: [
      void,
      GeneratorReturnType<typeof renameInApplicationConfiguration>,
      GeneratorReturnType<typeof renameInAPIs>,
    ] = yield all([
      call(renamePageEntitySaga, {
        entityId,
        oldName,
        newName,
        scope,
        namespace: "App",
      }),
      call(renameInApplicationConfiguration, {
        entityId,
        oldName,
        newName,
        configuration: appConfiguration,
      }),
      call(renameInAPIs, {
        oldName,
        newName,
        scope: ApplicationScope.APP,
        namespace: "App",
      }),
    ]);

    const {
      dsl: currentPageDSL,
    }: GeneratorReturnType<typeof getLayoutSavePayload> = yield call(
      getLayoutSavePayload,
      {
        lastSuccessfulWrite: lastSuccessfulWrite,
      },
    );

    try {
      rpcClient = yield call(connectToISocketRPCServer, agents, organization);
      if (!rpcClient) throw new Error("Failed to connect to ISocketRPC");

      const response: Awaited<
        ReturnType<typeof rpcClient.call.v2.application.modifyEntities>
      > = yield call(rpcClient.call.v2.application.modifyEntities, {
        applicationId,
        appConfiguration: newConfiguration,
        pages: [
          {
            pageId: currentPageId,
            dsl: currentPageDSL,
          },
        ],
        apis: newApis.map((api) => {
          return {
            apiId: api.id,
            dsl: api.apiPb,
            pageId: currentPageId,
          } satisfies ModifyEntitiesPayloadApi;
        }),
        renames: [
          {
            entityId,
            oldName,
            newName,
          },
        ],
        branch: branch?.name,
        lastSuccessfulWrite: lastSuccessfulWrite.valueOf(),
        signingRequired: isSigningRequired,
      });

      if (response.responseMeta?.error) {
        throw response.responseMeta.error;
      }

      yield put({
        type: ReduxActionTypes.UPDATE_LAST_SUCCESSFUL_WRITE,
        payload: new Date(response.data.updatedTime),
      });

      if (isOnPremise) {
        yield put({
          type: ReduxActionTypes.UPDATE_APPLICATION_HASH_TREE,
          payload: { tree: response.data.signature?.root },
        });
      }

      const currentAPIs: ReturnType<typeof selectAllV2Apis> = yield select(
        selectAllV2Apis,
      );
      const currentAPIIds = new Set(Object.keys(currentAPIs));
      const shouldFetchAPIs = (response.data.updatedAPIs ?? []).some((id) =>
        currentAPIIds.has(id),
      );

      if (shouldFetchAPIs) {
        yield callSagas([
          getAllV2ApisSaga.apply({
            applicationId,
            pageId: currentPageId,
          }),
        ]);
      }
    } catch (error) {
      sendErrorUINotification({
        message: `Failed to rename entity. ${(error as any).message}`,
      });
      yield put({
        type: ReduxActionErrorTypes.UPDATE_APPLICATION_ERROR,
        payload: { error },
      });
      return;
    } finally {
      unlock();
      rpcClient?.close();
    }
  }

  return;
}

export default createSaga(
  renameApplicationEntitySaga,
  "renameApplicationEntitySaga",
);
