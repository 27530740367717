import { PostApiCreateRequestBody } from "@superblocksteam/schemas";
import {
  Organization,
  WorkflowExecutionParamsKey,
  getNextEntityName,
} from "@superblocksteam/shared";

import { call, put, select } from "redux-saga/effects";
import { v4 as uuidv4 } from "uuid";
import { updatePartialApiInfo } from "legacy/actions/apiActions";
import { TriggerStepType } from "legacy/constants/ActionConstants";
import { ReduxActionTypes } from "legacy/constants/ReduxActionConstants";
import {
  getCurrentBranch,
  getCurrentPageId,
} from "legacy/selectors/editorSelectors";
import { getExistingWidgetNames } from "legacy/selectors/sagaSelectors";

import { selectAllApiUnionNames } from "store/slices/apisShared/selectors";

import { selectOnlyOrganization } from "store/slices/organizations";
import { ROOT, typeSafeEntries } from "store/utils/types";
import { createSaga } from "../../../utils/saga";

import { createV3Api } from "../client";
import slice from "../slice";
import { ApiDto, ApiTriggerType } from "../types";

function* createApiInternal(
  payload: PostApiCreateRequestBody,
): Generator<unknown, ApiDto, any> {
  let result: ApiDto | undefined = undefined;

  if (payload?.actions?.triggerType === ApiTriggerType.UI) {
    const widgetNames: string[] = yield select(getExistingWidgetNames);
    const apiNames: string[] = yield select(selectAllApiUnionNames);

    const allNames = [...widgetNames, ...apiNames];
    payload.name = getNextEntityName("API", allNames);
    payload.actions.name = getNextEntityName("API", allNames);
  }

  const { pluginExecutionVersions }: Organization = yield select(
    selectOnlyOrganization,
  );

  for (const [id, action] of typeSafeEntries(payload?.actions?.actions || {})) {
    if (payload?.actions?.actions) {
      // protobuf type rather than only part of it.
      payload.actions.actions[id] = {
        ...action,
        configuration: {
          ...action.configuration,
          superblocksMetadata: {
            pluginVersion: pluginExecutionVersions
              ? pluginExecutionVersions[action.pluginId]
              : "",
          },
        },
      };
    }
  }

  if (payload?.actions) {
    payload.actions.workflowParams = [
      { key: WorkflowExecutionParamsKey.BODY, value: {} },
    ];
    payload.actions.workflowQueries = [
      { key: WorkflowExecutionParamsKey.QUERY_PARAMS, value: {} },
    ];
  }

  const pageId = yield select(getCurrentPageId);
  payload.pageId = pageId;

  const generator = (triggerType: ApiTriggerType) => {
    switch (triggerType) {
      case ApiTriggerType.UI:
      case ApiTriggerType.WORKFLOW:
      case ApiTriggerType.SCHEDULE:
        return createV3Api;
      default:
        throw new Error("Invalid Trigger Type");
    }
  };

  const branch: ReturnType<typeof getCurrentBranch> = yield select(
    getCurrentBranch,
  );
  result = yield call(generator(payload.triggerType), branch?.name, payload);

  yield put({
    type: ReduxActionTypes.UPDATE_LAST_SUCCESSFUL_WRITE,
    payload: result?.updated,
  });

  if (result && payload?.actions?.triggerType === ApiTriggerType.UI) {
    yield put(
      updatePartialApiInfo({
        id: result.id,
        data: {
          id: result?.id,
          onError: [
            {
              id: uuidv4(),
              type: TriggerStepType.SHOW_ALERT,
              message: `{{${result.actions?.name}.error}}`,
              style: "error",
            },
          ],
          dynamicTriggerPathList: [{ key: "onError" }],
        },
      }),
    );
  }

  return result as ApiDto;
}

export const createApiSaga = createSaga(createApiInternal, "createApiSaga", {
  sliceName: "apis",
});

slice.saga(createApiSaga, {
  start(state, { payload }: { payload: PostApiCreateRequestBody }) {
    state.loading[ROOT] = true;
    delete state.errors[ROOT];
    // TODO: do we need this for v3? we're not sending a UI generated id anymore
    // newly created APIs have no deps
    // state.meta[payload.id] = {
    //   needsBindingExtraction: false,
    //   extractedBindings: [],
    //   concurrentRuns: 0,
    // } as ApiMeta;
  },
  success(state, { payload, meta }) {
    state.entities[payload.id] = payload;
    delete state.loading[ROOT];
  },
  error(state, { payload, meta }) {
    state.errors[ROOT] = { error: payload };
    delete state.loading[ROOT];
  },
});
