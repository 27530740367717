import { createReducer } from "@reduxjs/toolkit";
import { ApplicationScope } from "@superblocksteam/shared";
import {
  clearExpandedStateItems,
  toggleSidebarItem,
  updateApplicationSidebarKey,
  updateDeveloperPreferences,
  updateEditorPreferences,
  updateFocusedItems,
  updateIsFocusedItemsTracked,
  updateWorkflowSidebarKey,
} from "legacy/actions/editorPreferencesActions";
import { pageLoadSuccess } from "legacy/actions/pageActions";
import {
  EditorAppPreferences,
  DEFAULT_DEVELOPER_PREFERENCES,
  DeveloperPreferences,
} from "legacy/constants/EditorPreferencesConstants";
import { ItemKinds } from "legacy/pages/Editor/PropertyPane/ItemKindConstants";
import {
  formatDeveloperPreferences,
  formatEditorPreferences,
} from "legacy/utils/EditorPreferencesUtils";
import { STORAGE_KEYS } from "legacy/utils/StorageUtils";
import localStorage from "legacy/utils/localStorage";
import type { ApplicationPayload } from "legacy/constants/ReduxActionConstants";

export type FocusedItem = {
  type: ItemKinds;
  name: string;
  id: string;
  scope: ApplicationScope;
};

export type EditorPreferencesState = {
  // Persisted on local storage (see persistState).
  // Preferences for each application / workflow / scheduled jobs
  preferences: Record<ApplicationPayload["id"], EditorAppPreferences>;

  // General preferences that reflect editor temporal state
  // Not persisted
  general: {
    application: {
      sidebar: {
        selectedKey: string | undefined;
        focusedItems?: FocusedItem[];
        trackFocusedItems?: boolean;
        expandedItemNames?: Record<string, boolean>;
        expandedStateItems?: { name: string; scope: ApplicationScope }[];
      };
    };
    workflow: {
      sidebar: {
        selectedKey: string | undefined;
      };
    };
  };

  // Persisted on local storage (see persistState).
  // Developer preferences that users can actually customize
  developer: DeveloperPreferences;
};

const initialState: EditorPreferencesState = {
  preferences: {},
  general: {
    application: {
      sidebar: {
        selectedKey: undefined,
        trackFocusedItems:
          localStorage.getItem(STORAGE_KEYS.TRACK_FOCUSED_ITEMS) === "true",
      },
    },
    workflow: {
      sidebar: {
        selectedKey: undefined,
      },
    },
  },
  developer: DEFAULT_DEVELOPER_PREFERENCES,
};

const editorPreferencesReducer = createReducer(
  // Load initial state from redux
  () => {
    try {
      const serializedData = localStorage.getItem(STORAGE_KEYS.REDUX);
      if (!serializedData) return initialState;

      const data = JSON.parse(serializedData);
      return {
        ...initialState,
        preferences: formatEditorPreferences(data),
        developer: formatDeveloperPreferences(data),
        // Have to add the following because the initial state of 'editorPreferences' is merged at reducer level. 'editorPreferences.general' will be undefined if not added.
        // currently nothing in 'editorPreferences.general' should be persisted in local storage, but we can add later if needed.
        general: initialState.general,
      };
    } catch (err) {
      return initialState;
    }
  },
  (builder) =>
    builder
      .addCase(updateEditorPreferences, (state, action) => {
        const { applicationId, updatedPreferences, pageId } = action.payload;
        if (!state.preferences[applicationId]) {
          state.preferences[applicationId] = {};
        }
        state.preferences[applicationId][pageId] = updatedPreferences;
      })
      .addCase(updateApplicationSidebarKey, (state, action) => {
        state.general.application.sidebar.selectedKey =
          action.payload.selectedKey;
        state.general.application.sidebar.expandedStateItems =
          action.payload.expandedStateItems;

        if ("focusedItems" in action.payload) {
          state.general.application.sidebar.focusedItems =
            action.payload.focusedItems;
        }
      })
      .addCase(updateFocusedItems, (state, action) => {
        state.general.application.sidebar.focusedItems =
          action.payload.focusedItems;
      })
      .addCase(pageLoadSuccess, (state, action) => {
        if (
          state.general.application.sidebar.focusedItems?.some(
            (item) => item.scope === ApplicationScope.PAGE,
          )
        ) {
          state.general.application.sidebar.focusedItems = undefined;
        }
      })
      .addCase(clearExpandedStateItems, (state) => {
        state.general.application.sidebar.expandedStateItems = [];
      })
      .addCase(updateIsFocusedItemsTracked, (state, action) => {
        state.general.application.sidebar.trackFocusedItems =
          action.payload.isTracked;
      })
      .addCase(updateWorkflowSidebarKey, (state, action) => {
        state.general.workflow.sidebar.selectedKey = action.payload.selectedKey;
      })
      .addCase(updateDeveloperPreferences, (state, action) => {
        if (action.payload.key === "application") {
          state.developer.application = {
            ...state.developer.application,
            ...action.payload.update,
          };
        } else {
          state.developer.shared = {
            ...state.developer.shared,
            ...action.payload.update,
          };
        }
      })
      .addCase(toggleSidebarItem, (state, action) => {
        state.general.application.sidebar.expandedItemNames = {
          ...(state.general.application.sidebar.expandedItemNames ?? {}),
          ...action.payload,
        };
      }),
);

export default editorPreferencesReducer;
