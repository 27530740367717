import { VariableType } from "@superblocksteam/shared/src/types/api/control-flow";
import { get } from "lodash";
import { ExpandedDefCreator } from "autocomplete/ExpandedDefCreator";
import { AdditionalScopeProps, DefInfoMap } from "autocomplete/types";
import { getIconPathFromBlock } from "pages/Editors/ApiEditor/ControlFlow/common-block-type-info";
import { removeBindingsFromStringValue } from "pages/Editors/ApiEditor/ControlFlow/utils";
import { Resolutions } from "../backend-types";
import { EnrichedExecutionResult } from "../types";
import { ControlBlockLocator } from "./locators";
import {
  BlockType,
  ConditionControlBlock,
  GenericBlock,
  LoopControlBlock,
  ParallelControlBlock,
  ParallelWait,
  StepBlock,
  TryCatchControlBlock,
  VariablesControlBlock,
  LoopType,
  StreamControlBlock,
} from "./types";

const addDefinitionForBlockVar = (params: {
  varName: string;
  valueDescription?: string;
  derivationMarkdown?: string;
  blockName: string;
  valueType?: string;
  value?: unknown;
  rootDef: ExpandedDefCreator;
}) => {
  const {
    varName,
    valueDescription,
    blockName,
    rootDef,
    valueType,
    value,
    derivationMarkdown,
  } = params;
  const varDef = rootDef.addDefinition(varName, {
    doc: `Variable of ${blockName}`,
    rank: varName,
  });
  varDef
    .addDefinition("value", {
      doc: valueDescription,
      markdownDoc: derivationMarkdown,
      rank: 1,
      type: value === undefined ? valueType ?? "?" : undefined,
      value,
    })
    .suggestExpressionInRoot();
};

const addDefinitionForBlockOutput = (params: {
  outputDescription: string;
  rootDef: ExpandedDefCreator;
  blockName: string;
  thisBlockOutput: unknown;
}) => {
  const { outputDescription, rootDef, blockName, thisBlockOutput } = params;
  const blockDef = rootDef.addDefinition(blockName, {
    rank: blockName,
    doc: `${blockName} - One of the previous steps of the API`,
  });
  blockDef
    .addDefinition("output", {
      doc: outputDescription,
      rank: 1,
      value: thisBlockOutput,
    })
    .suggestExpressionInRoot();
};

// wraps code for markdown
const wrapBindingCode = (code: string) => {
  return `\n\`\`\`superblocks-binding\n${code}\n\`\`\`\n`;
};

type GetBlockVarContext = {
  childLocation?: ControlBlockLocator;
  thisBlockOutput?: unknown;
  executionResult?: EnrichedExecutionResult;
  fieldValues?: Resolutions;
  defCreatorOptions: {
    icon?: string;
    baseRanks?: Array<string | number>;
  };
  additionalScopeProps?: AdditionalScopeProps;
};

const getConditionalBlockVars = (
  block: ConditionControlBlock,
  context: GetBlockVarContext,
) => {
  const { childLocation, thisBlockOutput } = context;
  const rootDef = ExpandedDefCreator.newDef(context.defCreatorOptions);
  if (childLocation == null) {
    addDefinitionForBlockOutput({
      outputDescription: `The result of the last block in the evaluated branch of ${block.name}.`,
      rootDef,
      blockName: block.name,
      thisBlockOutput,
    });
  }
  return rootDef.computeRootDef();
};

const getLoopBlockVars = (
  block: LoopControlBlock,
  context: GetBlockVarContext,
) => {
  const { childLocation, thisBlockOutput } = context;
  const rootDef = ExpandedDefCreator.newDef(context.defCreatorOptions);
  if (childLocation == null) {
    addDefinitionForBlockOutput({
      outputDescription: `An array of the outputs of the last blocks in ${block.name}.`,
      blockName: block.name,
      rootDef,
      thisBlockOutput,
    });
  } else {
    const fieldValue = getFieldValue("range", context);
    if (block.config.type === LoopType.FOREACH) {
      let itemValue: undefined | unknown;
      if (Array.isArray(fieldValue) && fieldValue.length > 0) {
        itemValue = fieldValue[0];
      }
      const markdown = `This item will be an element from the evaluated range: ${wrapBindingCode(
        block.config.range,
      )}`;
      addDefinitionForBlockVar({
        varName: block.config.variables.item,
        valueDescription: `The item for the current iteration of ${block.name}.`,
        derivationMarkdown: !itemValue ? markdown : undefined,
        blockName: block.name,
        rootDef,
        value: itemValue,
      });
    }

    const lengthValue = Array.isArray(fieldValue)
      ? fieldValue.length
      : Number.isInteger(fieldValue)
      ? fieldValue
      : undefined;
    const indexMarkdown = lengthValue
      ? `The index for the current iteration of ${
          block.name
        }, ranging from **0 to ${lengthValue - 1}**`
      : `The index for the current iteration of ${
          block.name
        }, ranging from 0 to the length of the specified range: ${wrapBindingCode(
          block.config.range,
        )}`;

    addDefinitionForBlockVar({
      varName: block.config.variables.index,
      valueType: "number",
      valueDescription: `The index for the current iteration of ${block.name}`,
      derivationMarkdown: indexMarkdown,
      blockName: block.name,
      rootDef,
    });
  }
  return rootDef.computeRootDef();
};

const getParallelBlockVars = (
  block: ParallelControlBlock,
  context: GetBlockVarContext,
) => {
  const rootDef = ExpandedDefCreator.newDef(context.defCreatorOptions);
  const { childLocation, thisBlockOutput } = context;

  let formattedThisBlockOutput = thisBlockOutput;
  // Add Path1, Path2, etc to Parallel block output when the output is undefined
  if (block.config.static && thisBlockOutput === undefined) {
    Object.keys(block.config.static.paths as Record<string, unknown>).forEach(
      (pathName) => {
        formattedThisBlockOutput = {
          ...(formattedThisBlockOutput as Record<string, unknown>),
          [pathName]: undefined,
        };
      },
    );
  }

  if (childLocation == null) {
    if (block.config.wait === ParallelWait.WAIT_ALL) {
      addDefinitionForBlockOutput({
        blockName: block.name,
        rootDef,
        outputDescription: block.config.static
          ? `A map of the results of the last executed blocks in each path of ${block.name}`
          : `An array of the results of the last executed block in ${block.name}.`,
        thisBlockOutput,
      });
    }
    // otherwise, no referenceable output
  }

  if (block.config.dynamic) {
    const fieldValue = getFieldValue("dynamic.paths", context);

    let itemValue: undefined | unknown;
    if (Array.isArray(fieldValue) && fieldValue.length > 0) {
      itemValue = fieldValue[0];
    }
    const markdown = `This item will be an element from the evaluated range: ${wrapBindingCode(
      block.config.dynamic.paths,
    )}`;
    addDefinitionForBlockVar({
      varName: block.config.dynamic.variables.item,
      valueDescription: `The current item for the dynamic path in ${block.name}.`,
      derivationMarkdown: !itemValue ? markdown : undefined,
      blockName: block.name,
      rootDef,
      value: itemValue,
    });
  }

  return rootDef.computeRootDef();
};

const getTryCatchBlockVars = (
  block: TryCatchControlBlock,
  context: GetBlockVarContext,
) => {
  const rootDef = ExpandedDefCreator.newDef(context.defCreatorOptions);
  const { childLocation, thisBlockOutput } = context;
  if (childLocation == null) {
    addDefinitionForBlockOutput({
      blockName: block.name,
      rootDef,
      outputDescription: `The result of the last executed block in ${block.name}.`,
      thisBlockOutput,
    });
  } else if (
    childLocation.controlType === BlockType.TRY_CATCH &&
    childLocation.branchName === "catch"
  ) {
    addDefinitionForBlockVar({
      varName: block.config.variables.error,
      valueType: "string",
      valueDescription: `The error message thrown from within "try" in ${block.name}.`,
      blockName: block.name,
      rootDef,
    });
  }
  return rootDef.computeRootDef();
};

const getVariableBlockVars = (
  block: VariablesControlBlock,
  context: GetBlockVarContext,
) => {
  const rootDef = ExpandedDefCreator.newDef(context.defCreatorOptions);

  const endIndex = context.additionalScopeProps?.variableIndex;
  block.config.variables.forEach((varEntry, index) => {
    if (endIndex != null && index >= endIndex) {
      return;
    }
    const varDef = rootDef.addDefinition(varEntry.key, {
      doc: `Variable defined in ${block.name}.`,
      rank: varEntry.key,
    });

    const advancedGetMarkdown = `Example usage: ${wrapBindingCode(
      "await " + varEntry.key + ".get()",
    )}`;

    const advancedSetMarkdown = `Example usage: ${wrapBindingCode(
      "await " + varEntry.key + ".set(...)",
    )}`;

    const definitionMarkdown = `The value of ${
      varEntry.key
    } is set to the following in ${block.name}: ${wrapBindingCode(
      removeBindingsFromStringValue(varEntry.value),
    )}`;

    if (varEntry.type === VariableType.SIMPLE) {
      varDef
        .addDefinition("value", {
          doc: `The value of ${varEntry.key}.`,
          rank: 1,
          markdownDoc: definitionMarkdown,
        })
        .suggestExpressionInRoot();
      if (endIndex == null) {
        varDef
          .addDefinition("set", {
            doc: `Set the value of ${varEntry.key}.`,
            type: "fn()",
            customFnDef: "(value) -> void",
            rank: 2,
          })
          .suggestInvocationInRoot();
      }
    } else if (varEntry.type === VariableType.ADVANCED) {
      // Rank 3 and 4 here because if we eventually support both simple and advanced, we want simple to show first
      varDef
        .addDefinition("get", {
          doc: `Get the value of ${varEntry.key} as a promise.`,
          type: "fn()",
          customFnDef: "() -> Promise",
          markdownDoc: `${advancedGetMarkdown}${definitionMarkdown}`,
          rank: 3,
        })
        .suggestInvocationInRoot();
      if (endIndex == null) {
        varDef
          .addDefinition("set", {
            doc: `Set the value of ${varEntry.key}.`,
            type: "fn()",
            customFnDef: "(value) -> Promise",
            rank: 4,
            markdownDoc: `${advancedSetMarkdown}`,
          })
          .suggestInvocationInRoot();
      }
    }
  });
  return rootDef.computeRootDef();
};

const getStepBlockVars = (block: StepBlock, context: GetBlockVarContext) => {
  const rootDef = ExpandedDefCreator.newDef(context.defCreatorOptions);

  addDefinitionForBlockOutput({
    blockName: block.name,
    rootDef,
    outputDescription: `The returned result of ${block.name}`,
    thisBlockOutput: context.thisBlockOutput,
  });

  return rootDef.computeRootDef();
};

const getStreamVars = (
  block: StreamControlBlock,
  context: GetBlockVarContext,
) => {
  const rootDef = ExpandedDefCreator.newDef(context.defCreatorOptions);
  addDefinitionForBlockVar({
    varName: block.config.variables.item,
    valueType: "any",
    valueDescription: `The message for the current iteration of ${block.name}.`,
    blockName: block.name,
    rootDef,
  });
  return rootDef.computeRootDef();
};

// If childLocation is defined, then we are getting the variables that the
// block is making  available to child blocks.
// Otherwise, we are getting variables that are available to subsequent siblings.
const getBlockVars = (
  block: GenericBlock,
  params: {
    childLocation?: ControlBlockLocator;
    initialRanks: [depthRank: number, siblingRank: number];
    executionResult?: EnrichedExecutionResult;
    additionalScopeProps?: AdditionalScopeProps;
  },
): DefInfoMap => {
  const { childLocation, initialRanks, executionResult } = params;
  const thisBlockOutput = executionResult?.lastOutputs?.[block.name]?.output;
  const fieldValues = executionResult?.lastOutputs?.[block.name]?.fields;
  const context = {
    childLocation,
    thisBlockOutput,
    executionResult,
    fieldValues,
    defCreatorOptions: {
      icon: getIconPathFromBlock(block),
      baseRanks: [...initialRanks, block.name],
    },
    additionalScopeProps: params.additionalScopeProps,
  } satisfies GetBlockVarContext;

  switch (block.type) {
    case BlockType.CONDITION:
      return getConditionalBlockVars(block as ConditionControlBlock, context);
    case BlockType.LOOP:
      return getLoopBlockVars(block as LoopControlBlock, context);
    case BlockType.PARALLEL:
      return getParallelBlockVars(block as ParallelControlBlock, context);
    case BlockType.TRY_CATCH:
      return getTryCatchBlockVars(block as TryCatchControlBlock, context);
    case BlockType.VARIABLES:
      return getVariableBlockVars(block as VariablesControlBlock, context);
    case BlockType.STREAM:
      return getStreamVars(block as StreamControlBlock, context);
    case BlockType.RETURN:
      return {};
    case BlockType.BREAK:
      return {};
    case BlockType.WAIT:
      return {};
    case BlockType.THROW:
      return {};
    case BlockType.STEP:
      return getStepBlockVars(block as StepBlock, context);
    case BlockType.SEND:
      return {};

    default: {
      const exhaustiveCheck: never = block.type;
      throw new Error(
        `Unhandled block type in getBlockVars: ${exhaustiveCheck}`,
      );
    }
  }
};

function getFieldValue(pathToField: string, context: GetBlockVarContext) {
  return get(context.fieldValues, pathToField)?.value;
}

export default getBlockVars;
