import React from "react";
import { useSelector } from "react-redux";
import { toast, ToastOptions, ToastContainer } from "react-toastify";
import styled from "styled-components";
import { PropertyPaneLayoutConfig } from "legacy/constants/WidgetConstants";
import { useAppDispatch, useAppSelector } from "store/helpers";
import Icon, { IconSize } from "./Icon";
import Text, { TextType } from "./Text";
import { CommonComponentProps, Classes, Variant } from "./common";
import { slideAndFadeCSS, FadeAndSlide } from "./slideAndFadeCSS";
import type { ReduxActionType } from "legacy/constants/ReduxActionConstants";
import type { AppState } from "store/types";
import "react-toastify/dist/ReactToastify.css";

type ToastProps = ToastOptions &
  CommonComponentProps & {
    force?: boolean;
    text: string;
    variant?: Variant;
    duration?: number;
    onUndo?: () => void;
    dispatchableAction?: { type: ReduxActionType; payload: any };
    hideProgressBar?: boolean;
  };

const WrappedToastContainer = styled.div<{ right: number }>`
  .Toastify__toast-container {
    width: auto;
    padding: 0px;
    margin-right: ${(props) => props.right}px;
  }
  .Toastify__toast--default {
    background: transparent;
  }
  .Toastify__toast {
    cursor: auto;
    min-height: auto;
    border-radius: 0px !important;
    font-family: ${(props) => props.theme.legacy.fonts.text};
    margin-bottom: ${(props) => props.theme.legacy.spaces[4]}px;
  }
  .Toastify__toast-container--top-right {
    top: 4em;
  }
  ${slideAndFadeCSS}
`;

export const StyledToastContainer = (props: ToastOptions) => {
  const propertyPaneWidth =
    useAppSelector((state) => state.legacy.ui.propertyPane.width) ??
    PropertyPaneLayoutConfig.defaultWidth;
  const isPropertyPaneVisible = useSelector(
    (state: AppState) => state.legacy.ui.propertyPane.isVisible,
  );

  return (
    <WrappedToastContainer
      right={isPropertyPaneVisible ? propertyPaneWidth : 0}
    >
      <ToastContainer {...props} transition={FadeAndSlide} />
    </WrappedToastContainer>
  );
};

const ToastBody = styled.div<{
  variant?: Variant;
  isUndo?: boolean;
  dispatchableAction?: { type: ReduxActionType; payload: any };
}>`
  max-width: 264px;
  background: ${(props) => props.theme.legacy.colors.toast.bg};
  padding: ${(props) => props.theme.legacy.spaces[4]}px
    ${(props) => props.theme.legacy.spaces[5]}px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow-wrap: anywhere;
  border-radius: 4px;

  box-shadow: 0px 0px 1px 0px rgba(34, 39, 47, 0.32),
    0px 12px 32px -8px rgba(34, 39, 47, 0.16),
    0px 1px 3px 0px rgba(34, 39, 47, 0.12);

  .${Classes.ICON} {
    cursor: auto;
    margin-right: ${(props) => props.theme.legacy.spaces[3]}px;
    svg {
      path {
        fill: ${(props) =>
          props.variant === Variant.warning
            ? props.theme.legacy.colors.toast.warningColor
            : props.variant === Variant.danger
            ? "#FFFFFF"
            : props.variant === Variant.success
            ? "#36AB80"
            : "#9F9F9F"};
      }
      rect {
        ${(props) =>
          props.variant === Variant.danger
            ? `fill: ${props.theme.legacy.colors.toast.dangerColor}`
            : null};
      }
    }
  }

  .${Classes.TEXT} {
    color: ${(props) => props.theme.legacy.colors.toast.textColor};
    user-select: none;
  }

  ${(props) =>
    props.isUndo || props.dispatchableAction
      ? `
    .undo-section {
      flex-grow: 0;
      flex-shrink: 0;
      flex-basis: auto;
    }

    .undo-section .${Classes.TEXT} {
      cursor: pointer;
      margin-left: ${props.theme.legacy.spaces[8]}px;
      color: ${props.theme.legacy.colors.toast.undo};
      line-height: 18px;
      font-weight: 600;
    }
    `
      : null}
`;

const FlexContainer = styled.div`
  display: flex;
  align-items: flex-start;
`;

const ToastComponent = (props: ToastProps & { undoAction?: () => void }) => {
  const dispatch = useAppDispatch();

  return (
    <ToastBody
      variant={props.variant || Variant.info}
      isUndo={props.onUndo ? true : false}
      dispatchableAction={props.dispatchableAction}
      data-test="toast-action"
    >
      <FlexContainer>
        {props.variant === Variant.success ? (
          <Icon name="success" size={IconSize.XXL} />
        ) : props.variant === Variant.warning ? (
          <Icon name="warning" size={IconSize.XXL} />
        ) : null}
        {props.variant === Variant.danger ? (
          <Icon name="error" size={IconSize.XXL} />
        ) : null}
        <Text type={TextType.P1}>{props.text}</Text>
      </FlexContainer>
      {props.onUndo || props.dispatchableAction ? (
        <div className="undo-section">
          <Text
            type={TextType.H6}
            onClick={() => {
              if (props.dispatchableAction) {
                dispatch(props.dispatchableAction);
                props.undoAction && props.undoAction();
              } else {
                props.undoAction && props.undoAction();
              }
            }}
            data-test="toast-undo"
          >
            UNDO
          </Text>
        </div>
      ) : null}
    </ToastBody>
  );
};

export const Toaster = {
  show: (config: ToastProps) => {
    if (typeof config.text !== "string") {
      console.error("Toast message needs to be a string");
      return;
    }
    if (config.variant && !Object.values(Variant).includes(config.variant)) {
      console.error(
        "Toast type needs to be a one of " + Object.values(Variant).join(", "),
      );
      return;
    }

    // Stringified JSON is a long, but valid key :shrug:
    const toastId = JSON.stringify(config);
    toast(
      <ToastComponent
        undoAction={() => {
          toast.dismiss(toastId);
          config.onUndo && config.onUndo();
        }}
        {...config}
      />,
      {
        toastId: toastId,
        pauseOnHover: !config.dispatchableAction && !config.hideProgressBar,
        pauseOnFocusLoss: !config.dispatchableAction && !config.hideProgressBar,
        autoClose: false,
        draggable: true,
        closeOnClick: false,
        hideProgressBar: config.hideProgressBar,
        position: config.position,
      },
    );
    // Update autoclose everytime to keep resetting the timer.
    toast.update(toastId, {
      autoClose: config.duration || 5000,
    });
  },
  clear: () => toast.dismiss(),
};
