import {
  VersionedEntityType,
  ScheduleState,
  sanitizeV2RequestBody,
} from "@superblocksteam/shared";
import { call, delay, put, select } from "redux-saga/effects";
import envs from "env";
import { API_STATUS_CODES } from "legacy/constants/ApiConstants";
import { ReduxActionTypes } from "legacy/constants/ReduxActionConstants";
import { SideBarKeys } from "legacy/pages/Editor/constants";
import { getWorkflowSidebarKey } from "legacy/selectors/editorPreferencesSelector";
import {
  getCurrentBranch,
  getIsLeftPanePinned,
} from "legacy/selectors/editorSelectors";
import selectLastSuccessfulWrite from "legacy/selectors/successfulWriteSelector";
import { lock } from "store/slices/apisShared/sharedPersistApiLock";
import { getV2ApiId } from "store/slices/apisV2/utils/getApiIdAndName";
import { fetchCommitsSaga } from "store/slices/commits";
import { Flag, selectFlagById } from "store/slices/featureFlags";
import { HttpError, EntitiesErrorType } from "store/utils/types";
import logger from "utils/logger";
import { signAndUpdateApi } from "utils/resource-signing";
import { findAndMarshalProtoBlocks } from "../../../../utils/marshalProto";
import { callSagas, createSaga, SagaType } from "../../../utils/saga";
import { clearV2DirtyState } from "../actions";
import * as BackendTypes from "../backend-types";
import { persistV2Api } from "../client";
import { selectV2ApiMetaById } from "../selectors";
import slice, { type ApiDtoWithPb } from "../slice";

export type PersistApiPayload = {
  api: BackendTypes.Api;
  scheduleState?: ScheduleState;
  updatingApiCallId?: number; // if this persist was triggered from an update call, this is its call id, which is used for hydrateBlock
  updated?: Date;
};

export function* persistV2ApiInternal({
  api: updatedApi,
  scheduleState,
  updated: lastUpdatedTime,
}: PersistApiPayload) {
  // The API might be deleted or on a deletion process.
  // E.g: if code formatting was triggered because of API deletion
  const apiMeta: ReturnType<typeof selectV2ApiMetaById> = yield select(
    selectV2ApiMetaById,
    updatedApi.metadata.id,
  );
  if (!apiMeta || apiMeta.isDeleting) return;

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  let unlock = () => {};
  try {
    unlock = yield call(lock, updatedApi.metadata.id);
    yield put(clearV2DirtyState.create({ id: updatedApi.metadata.id }));
    const lastSuccessfulWrite: Date = yield select(selectLastSuccessfulWrite);
    const superblocksSupportUpdateEnabled: boolean = yield select(
      selectFlagById,
      Flag.ENABLE_SUPERBLOCKS_SUPPORT_UPDATES,
    );

    findAndMarshalProtoBlocks(updatedApi);
    sanitizeV2RequestBody(updatedApi);

    const branch: ReturnType<typeof getCurrentBranch> = yield select(
      getCurrentBranch,
    );
    try {
      yield call(signAndUpdateApi, updatedApi);
    } catch (e: any) {
      throw new Error("Failed to sign API");
    }
    const newMeta = { ...updatedApi.metadata };
    delete newMeta.folder;

    const result: Awaited<ReturnType<typeof persistV2Api>> = yield call(
      persistV2Api,
      {
        api: {
          ...updatedApi,
          metadata: newMeta,
        },
        scheduleState,
        lastSuccessfulWrite:
          Number(lastSuccessfulWrite) === -1
            ? lastUpdatedTime
            : lastSuccessfulWrite,
        superblocksSupportUpdateEnabled,
        branch: branch?.name,
      },
    );
    if (result.apiPb === null) {
      throw new Error("apiPb was not found");
    }
    yield put({
      type: ReduxActionTypes.UPDATE_LAST_SUCCESSFUL_WRITE,
      payload: result.updated,
    });

    // we also need to refetch the autosaves if the versions panel is pinned
    // but only for workflows and scheduled jobs here, applications are handled inside EvaluationsSaga
    const isLeftPanePinned: boolean = yield select(getIsLeftPanePinned);
    const sidebarKey: SideBarKeys = yield select(getWorkflowSidebarKey);
    if (
      sidebarKey === SideBarKeys.VERSIONS &&
      isLeftPanePinned &&
      !updatedApi.trigger.application
    ) {
      yield delay(1000);

      yield callSagas([
        fetchCommitsSaga.apply({
          entityId: updatedApi.metadata.id,
          entityType: updatedApi.trigger.workflow
            ? VersionedEntityType.WORKFLOW
            : VersionedEntityType.SCHEDULED_JOB,
        }),
      ]);
    }

    return result;
  } catch (err) {
    logger.debug(`Errored when persisting the API: ${err}`);
    throw err;
  } finally {
    unlock();
  }
}

export const persistV2ApiSaga = createSaga(
  persistV2ApiInternal,
  "persistV2ApiSaga",
  {
    sliceName: slice.name,
    type: SagaType.Throttled,
    delay: Number(envs.get("SUPERBLOCKS_UI_PERSIST_API_DEBOUNCE_MS")),
    keySelector: (payload) => payload.api.metadata.id,
  },
);

slice.saga(persistV2ApiSaga, {
  start(state, { payload }) {
    state.meta[payload.api.metadata.id] =
      state.meta[payload.api.metadata.id] ?? {};
    state.meta[payload.api.metadata.id].saving = true;
    state.meta[payload.api.metadata.id].editedSinceLastExecution = true;
  },
  success(state, { payload, meta }) {
    if (!payload || !payload.apiPb) {
      return;
    }

    if (!state.meta[meta.args.api.metadata.id]) {
      // This happens if the user has navigated away from the page before the api has been saved, because the clearErrors
      // action happens before the persist is completed.
      return;
    }

    // I'm pretty sure apiId and meta.args.api.metadata.id will always be the same as apiId, since the api has already
    // been created and thus the randomly generated uuid has been replaced by the real id.
    // I don't have time to test this theory though, so matching the old code for now
    const payloadDto = payload as ApiDtoWithPb;
    const apiId = getV2ApiId(payloadDto);

    if (!state.meta[meta.args.api.metadata.id].isUpdating) {
      state.entities[apiId] = {
        ...payloadDto,
        apiPb: {
          ...state.entities[apiId].apiPb,
          metadata: payloadDto.apiPb.metadata,
          trigger: payloadDto.apiPb.trigger,
          // We don't update the blocks as it will cause a re-render of the action config forms
          // with data that might be out of date vs. what the user has typed in. Rather than have to protect against
          // this in the component, we just don't update redux with the value from the server here
          blocks: state.entities[apiId].apiPb.blocks,
        },
      };
    }
    delete state.meta[apiId].saving;
    delete state.errors[apiId];
    state.meta[apiId].savingFailuresCount = 0;
  },
  error(state, { payload, meta }) {
    state.meta[meta.args.api.metadata.id].dirty = true;
    state.errors[meta.args.api.metadata.id] = {
      error: payload,
      type: EntitiesErrorType.SAVE_ERROR,
    };
    delete state.meta[meta.args.api.metadata.id].saving;
    state.meta[meta.args.api.metadata.id].savingFailuresCount =
      (state.meta[meta.args.api.metadata.id].savingFailuresCount ?? 0) + 1;

    if (
      payload instanceof HttpError &&
      payload.code === API_STATUS_CODES.RESOURCE_CONFLICT
    ) {
      state.errors.stale = { error: payload };
    }
  },
});
