import {
  PostApiCommitCreateResponseBody,
  PostApplicationCommitCreateResponseBody,
  VersionedEntityType,
} from "@superblocksteam/shared";
import { call, select } from "redux-saga/effects";
import { getCurrentBranch } from "legacy/selectors/editorSelectors";
import { fetchApiSaga } from "store/slices/apis";
import { fetchV2ApiSaga } from "store/slices/apisV2";
import { getEnvironment } from "store/slices/application/selectors";
import { Flag, Flags, selectFlags } from "store/slices/featureFlags";
import { ROOT } from "store/utils/types";
import { sendErrorUINotification } from "utils/notification";
import { callSagas, createSaga } from "../../../utils/saga";
import { deployCommit as deployCommitCall } from "../client";
import slice from "../slice";
import { fetchDeploymentsSaga } from "./fetchDeployments";

function* deployCommitSaga({
  entityId,
  entityType,
  commitId,
  deployMessage,
}: {
  entityId: string;
  entityType: VersionedEntityType;
  commitId: string;
  deployMessage: string;
}) {
  const result:
    | PostApplicationCommitCreateResponseBody
    | PostApiCommitCreateResponseBody = yield call(
    deployCommitCall,
    entityId,
    entityType,
    commitId,
    deployMessage,
  );
  if (entityType !== VersionedEntityType.APPLICATION) {
    const environment: string = yield select(getEnvironment);
    const flags: Flags = yield select(selectFlags);
    const currentBranch: ReturnType<typeof getCurrentBranch> = yield select(
      getCurrentBranch,
    );
    const branch = result.branch ?? currentBranch?.name;
    const isControlFlowEnabled = flags[Flag.SHOW_CONTROL_FLOW_WORKFLOWS_JOBS];
    if (isControlFlowEnabled) {
      yield callSagas([
        fetchV2ApiSaga.apply({
          apiId: entityId,
          environment,
          branch,
        }),
      ]);
    } else {
      yield callSagas([fetchApiSaga.apply({ apiId: entityId, environment })]);
    }
  } else {
    yield callSagas([fetchDeploymentsSaga.apply({ entityId, entityType })]);
  }
  return {
    result,
    commitId,
  };
}

const deployCommit = createSaga(deployCommitSaga, deployCommitSaga.name, {
  sliceName: slice.name,
});

export { deployCommit as deployCommitSaga };

slice.saga(deployCommit, {
  start(state) {
    state.loading[ROOT] = true;
  },
  success(state, { payload }) {
    state.loading[ROOT] = false;
  },
  error(state, { payload }) {
    state.errors[ROOT] = { error: payload };
    state.loading[ROOT] = false;
    sendErrorUINotification({
      message: String(payload),
    });
  },
});
